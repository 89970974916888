.comments-container {
  border-top: 1px solid #979797;
  margin-top: 15px;
  margin-bottom: 15px;

  .comments {
    &-headline {
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: bold;
      color: $green;
      padding-bottom: 0.25em;
      padding-top: 1.5em;
      text-align: center;
    }

    &-area {
      width: 650px;
      max-width: 100%;
      @extend %center-block;
    }

    &-title {
      display: none;
    }
  }

  .comment {
    + .comment {
      margin-top: 24px;
    }

    .children {
      display: none;
    }

    &-list {
      list-style-type: none;
      padding: 0;
      // margin: 0;
    }

    &-body {
      padding: 10px 12px 15px;
      border: 1px solid #969696;
      background-color: $white;
      position: relative;
    }

    &-author {
      padding-left: 55px;
      position: relative;
      padding-top: 5px;

      > img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .fn {
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: normal;
        font-style: initial;
        color: #2D2D2D;
        display: block;
      }

      .says {
        display: none;
      }
    }

    &-meta {
      padding-left: 55px;

      > a {
        font-family: $font-secondary;
        font-weight: 200;
        font-size: 12px;
        color: #8A8A8A;
      }

      ~ p {
        font-family: $font-secondary;
        font-size: 14px;
        margin-top: 15px;
        font-weight: 200;
      }
    }

    &-awaiting-moderation {
      display: none;
    }

    .reply {
      display: none;
    }

    &-reply-title {
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: bold;
      color: $green;
      text-align: center;
    }

    &-respond {
      padding-top: 15px;
    }

    &-form {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;

      label {
        display: none;
      }

      input,
      textarea {
        font-size: 14px;
        font-style: italic;
        color: #969696;
        background-color: $white;
        border-radius: 0;
        border: 1px solid #969696;
        outline: none;
        box-shadow: none;
        width: 100%;
        padding: 10px 12px;
      }

      textarea {
        height: 50px !important;
      }

      input {
        height: 40px;
      }

      &-url {
        display: none;
      }

      &-comment {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 0;
      }

      &-author,
      &-email {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 0;
      }

      @include mq('phone-wide', max) {
        &-author,
        &-email {
          width: 100%;
        }
      }
    }

    &-notes {
      display: none;
    }
  }

  .form-submit {
    width: 100%;
    text-align: center;
    margin-top: 0;
    padding: 20px 15px;
    
    #submit {
      font-family: $font-secondary;
      font-size: 16px;
      font-weight: 500;
      font-style: initial;
      background-color: #A48405;
      color: $white;
      width: 315px;
      max-width: 100%;
      height: 50px;
      border: 0;
      cursor: pointer;

      &:hover {
        background-color: lighten(#A48405, 5%);
      }
    }

    @include mq('phablet', max) {
      #submit {
        width: 100%;
      }
    }
  }
}