.btn-more {
	text-align: center;
	margin: 5px 0 35px;
	// margin-bottom: 20px;

	button {
		font-family: SFProDisplay;
		font-weight: bold;
		font-size: 16px;
		text-transform: uppercase;
		padding: .5em 3em;
		@extend %btn;
		@include button-variant($green, $white, $green);

		&:hover {
			color: $white;
			background-color: $green;
		}
	}
}