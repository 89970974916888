/* COLUMNISTS SHORTCUT */
.columnists-shortcut {
	&__content {
		margin-top: -20px;
		width: 100%;
    border: solid 1px $gray;
    border-top: 0;
    padding: 0 7px;
	}

	&__list {}

	&__item {
  	display: flex;
  	align-items: center;
  	border-bottom: solid 1px $gray;
  	padding: 10px 0;

		&-img {
			width: 66px;
	    height: 66px;
	    border-radius: 50%;
	    overflow: hidden;
	    margin-right: 10px;
	    flex: 0 0 auto;
		}

		&-text {
			&-author {
				font-family: SFProDisplay;
				font-size: 12px;
				font-weight: 500;
			}

			&-title {
				font-family: RobotoSlab;
				font-size: 16px;
				margin-top: 5px;
				display: block;
			}
		}
  }

  &__action {
  	text-align: center;
  	padding: 11px 0 14px;
  	@include link-arrow($green);
  }
}


/* COLUMNISTAS PAGE */
.columinists {
	.columnists-shortcut {
		&__item {
			border-bottom: 0;
			margin-bottom: 5px;

			&-text {
				&-author {
					font-size: 14px;
				}

				&-title {
					margin-bottom: 5px;
				}

				&-day {
					font-family: $font-secondary;
					font-weight: normal;
					font-size: 12px;
				}
			}
		}
	}

	&__list{
		margin-top: 15px;
	}
	&__item {
		margin-bottom: 40px;

		&-content{
			display: flex;
			padding-right: 30px;
		}

		&-img {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			flex: 0 0 auto;
			padding: 0;
			margin-top: 5px;
			margin-right: 12px;
		}

	
		&-subtitle {
			font-size: 14px;
			font-family: 'SFProDisplay';
			font-weight: 500;
			margin-bottom: 5px;
		}

		&-title {
			font-size: 18px;
			line-height: 1.22;
			letter-spacing: 0.4px;
		}
		
	}

	@media (max-width: 992px) {
		&__item {
			margin-bottom: 20px;
			&-content {
				padding-right: 0;
			}
		}
	}
	@media (max-width: 768px) {
		.columnists-shortcut {
			&__item {
				flex-wrap: wrap;
				width: 260px;
				margin-left: auto;
				margin-right: auto;

				&-img {
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 10px;
				}

				&-text {
					width: 100%;
					text-align: center;

					&-author {
						font-size: 14px;
					}

					&-title {
						margin-bottom: 5px;
					}

					&-day {
						font-family: $font-secondary;
						font-weight: normal;
						font-size: 12px;
					}
				}
			}
		}

		&__item {

			&-title{
				font-size: 15px;
			}
		}
	}
}


