.social-simple {
	&__item {
		display: inline-block;
		font-size: 0;

		+ li {
			// margin-left: 8px;
			margin-left: 6px;
		}

		a {
			font-family: 'SFProDisplay';
			// font-size: 11px;
			font-weight: 500;
			display: flex;
			justify-content: center;
			align-items: center;

			&::before {
				content: '';
				background-image: url('../images/sprite.png');
				background-repeat: no-repeat;
				background-size: 106px 71px;
				display: inline-block;
				vertical-align: middle;
			}

			&.whatsapp {
				margin-right: 22px;

				&::before {
					width: 19px;
					height: 19px;
					background-position: -3px -26px;
				}
			}

			&.facebook {
				// &::before {
				// 	width: 10px;
				// 	height: 17px;
				// 	background-position: -38px -26px;
				// }

				svg {
					fill: #3c5896;
				}
			}
			
			&.twitter {
				// &::before {
				// 	width: 21px;
				// 	height: 17px;
				// 	background-position: -2px -51px;
				// }

				svg {
					fill: #009acf;
				}
			}

			&.instagram {
				// &::before {
				// 	width: 18px;
				// 	height: 19px;
				// 	background-position: -60px -2px;
				// }
				svg {
					fill: #c74c4d;
				}
			}

			&.soundcloud {
				// &::before {
				// 	width: 18px;
				// 	height: 19px;
				// 	background-position: -60px -25px;
				// }
				svg {
					fill: #ff6a22;
				}
			}

			&.youtube {
				// &::before {
				// 	width: 25px;
				// 	height: 17px;
				// 	background-position: -2px -3px;
				// }
				svg {
					fill: $red-dark;
				}
			}

			&.email {
				// &::before {
				// 	width: 21px;
				// 	height: 17px;
				// 	background-position: -32px -3px;
				// }

				svg {
					fill: $yellow;
				}
			}

			&.link {
				svg {
					stroke: $black;
				}
			}

			span {
				display: inline-block;
				margin-left: 5px;
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}


/**
 * SOCIAL SHARED
 */
.social-share {
	position: relative;

	&.is-open {
		.social-share__list {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__btn {
		width: 36px;
		height: 36px;
		background-color: #c6000d;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 1;
		@extend %btn;

		svg {
			width: 20px;
			height: 20px;
			fill: $white;
			display: inline-block;
			vertical-align: middle;
		}
	}

	&__list {
		// display: none;
		// display: flex;
		justify-content: end;
		align-items: center;
		position: absolute;
		left: 0;
		top: 36px;
		transform: translateY(-100%);
		opacity: 0;
		transition: all 200ms ease 50ms;
		// margin: 15px 0; // para home featured

	  li {
	  	width: 36px;
	    height: 36px;
	    // border-radius: 50%; // para home featured
	    // padding: 8px;
			// margin-right: 8px; // para home featured

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;

		    svg {
		    	width: 20px;
		    	height: 20px;
		    	fill: $white;
		    }
			}

	    &:nth-child(1) {
	    	background-color: #475993;
	    }

	    &:nth-child(2) {
	    	background-color: #0099cc;
	    }

	    &:nth-child(3) {
	    	background-color: #fecc0a;
	    }
	  }

	  // @include mq('tablet', min) {
	 	// 	position: absolute;
			// padding: 0px;
			// margin: 0;
			// margin-top: 36px;
			// top: 0;
			// right: 0;
			// width: 36px;
			// display: block;
			
			// li {
			// 	padding: 5px;
			// 	border-radius: 0;
			// 	margin-right: 0;
			// }
	 	// }
	}

	@include mq('tablet', max) {
		display: none;
	}
}