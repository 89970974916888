.partners {
	margin: 15px 0;

	&__gallery {
		.item {
			img {
				height: 40px;
				width: auto;
			}
		}
	}
	
	@include mq('tablet', min) {
		margin: 20px 0;

		&__gallery {
			.owl-carousel {
				border: 1px solid #dcdcdc;
				padding: 2px 10px;
			}

			.item {
				img {
					height: 40px;
					width: auto;
				}
			}
		}
  }
}