.u-hidden-tablet {
	@include mq('tablet', max) {
		display: none !important;
	}
}

.u-visible-tablet {
	@include mq('tablet') {
		display: none !important;
	}
}

.u-hidden-tablet-wide {
	@include mq('tablet-wide', max) {
		display: none !important;
	}	
}

.u-visible-tablet-wide {
	@include mq('tablet-wide') {
		display: none !important;
	}	
}

.u-list-inline {
	font-size: 0;

	> li {
		display: inline-block;
		font-size: initial;
		margin-left: 5px;
		margin-right: 5px;
	}
}

.u-align-left {
	text-align: left;
}

.u-align-right {
	text-align: right;
}

.u-align-center {
	text-align: center;
}

.u-ad-265x350 {
	width: 265px;
  height: 350px;
}

.u-text-color-default {
	color: $black !important;
}

.u-mt--lv4 {
	margin-top: 24px !important;
}

.u-full-width {
	width: 100% !important;
}

.u-relative {
	position: relative !important;
}

.u-height-full {
	height: 100vh !important;
}