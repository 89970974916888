.news-main-top {
	margin-bottom: 20px;

	@include mq('tablet', max) {
		// width: calc(100% + 10px);
		padding-left: 0;
		padding-right: 0;
		// margin-left: -5px;
	}
}
.pad20 {
	padding: 0 20px;
}

.news__more {
	position: relative;
	&-gallery {
		.owl-carousel {
			.owl-nav {
				display: none;
			}
		}

		&::after {
	  	content: '';
			width: 25px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			background: linear-gradient(to right, rgba($white, 0) 0, rgba($white, 0.8) 60%, rgba($white, 1) 100%);
			z-index: 1;
		}

		&::before {
	  	content: '';
			width: 25px;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background: linear-gradient(to left, rgba($white, 0) 0, rgba($white, 0.8) 60%, rgba($white, 1) 100%);
			z-index: 2;
		}
	}

	.is-sticky & {
		position: fixed;
    top: calc(45px + 15px);
		width: 100%;
		z-index: 10;
	}
}

@include mq('tablet', min) {
	.news__more-gallery {
		.owl-carousel {
			.owl-nav {
				display: block;
			}
		}

		&::after, &::before {
  		width: 0;
  		height: 0;
  		background: $transparent;
  	}
	}
}

.news-list {
	&.owl-carousel {
		display: block;
		margin-left: 0;
		margin-right: 0;

		[class*="col-"] {
			width: initial;
			max-width: initial;
			flex: initial;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.owl-carousel {
		.news-card{
			margin-bottom: 12px;
		}
	}
}

.news-card {
	margin-bottom: 30px;

	&--row {
		margin-bottom: 24px !important;

		.news-card__text {
			padding: 12px 15px !important;
			h3 {
				margin-bottom: 5px !important;
			}
			h2 {
				margin-top: 0;
			}
			p {
				font-size: 14px;
			}
		}

		@include mq('desktop', max) {
			.news-card__box {
				display: block !important;
			}

			.news-card__title {
				font-size: 18px;
			}

			.news-card__text {
				p {
					font-size: 12px;
				}
			}
		}

		@include mq('tablet-wide', max) {
			.news-card__title {
				font-size: 16px;
			}

			.news-card__text {
				p {
					display: none;
				}
			}
		}

		@include mq('tablet', max) {
			margin-bottom: 10px !important;

			&:last-of-type {
				margin-bottom: 25px !important;
			}
			.news-card__share {
				display: none;
			}

			.news-card__box {
				width: 170px !important;
				flex: 0 0 auto !important;
			}

			.news-card__text {
				background-color: $white !important;
				width: 100%;
			}
		}

		@include mq('phone-wide', max) {
			.news-card__box {
				width: 120px !important;
			}

			.news-card__title {
				font-size: 14px;
			}

			.news-card__text {
				padding: 0 0 0 10px !important
			}
		}
	}

	&--event {
		.news-card {
			&__info {
				background-color: lighten($gray, 12%);
				border: 1px solid lighten($gray, 5%);
				padding: 8px 9px;

				p {
					font-family: $font-secondary;
					font-weight: normal;
					font-size: 14px;
					letter-spacing: 0;
				}
			}

			&__title {
				margin-bottom: .75em;
				color: $violet;
			}

			&__date {
				font-family: $font-secondary;
				font-weight: normal;
				font-size: 12px;
				color: $black;
			}
		}
	}

	&--social {
		.news-card {
			&__info {
				background-color: lighten($gray, 8%) !important;
				border: 0 !important;
			}
		}
	}

	&.gallery {
		.news-card__image {
			&::after {
				background-image: url('../images/home/i-galeria.png');
			}
		}
	}

	&.video {
		.news-card__image {
			&::after {
				background-image: url('../images/home/i-video.png');
			}
		}
	}

	&.audio {
		.news-card__image {
			&::after {
				background-image: url('../images/home/i-audio.png');
			}
		}
	}

	&--wrapped {
		margin-bottom: 0;

		.news-card {
			&__info {
				position: absolute;
				width: 100%;
				bottom: 0;
				background-color: rgba($black, .65);
				padding: 0 15px 15px;
				box-shadow: 0 -10px 20px rgba($black, .65);

				h2 {
					line-height: 1.3;
				}
			}

			&__subtitle {
				color: $white;
				margin-top: 0;

				&::before{
					background-color: $white;
				}
			}

			&__title {
				a {
					color: $white;
				}
			}
		}

		&__featured {
			.news-card {
				&__info {
					background-color: $transparent;
					background-image: linear-gradient(rgba($black, .05) 0, rgba($black, 1));
					box-shadow: none;
				}
			}
		}

		@include mq('tablet', max) {
			.news-card {
				&__info {
					padding: 0 10px 10px;

					h2 {
						font-size: 16px;
					}
				}
			}
		}
	}

	&-group {
		.news-card {
			+ .news-card {
				margin-top: 12px;
			}

			&__image {
				&::after {
					top: calc(50% - 22px);
				}	
			}
		}
	}

	&__share {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}

	&__box {
		position: relative;
		overflow: hidden;
	}
	
	&__image {
		position: relative;

		&:after {
	    content: '';
	    width: 45px;
	    height: 45px;
	    display: block;
	    background-repeat: no-repeat;
	    background-position: center;
	    background-size: 100%;
	    z-index: 1;
	    @include center-elm(true, true);
		}
	}

	&__link {
		display: block;
	}

	&__info {
		width: 100%;
		padding: 6px 9px 0;
	}

	&__date {
		font-family: RobotoSlab;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: #585858;
    display: block;
	}
	
	&__subtitle {
		text-transform: uppercase;
    font-family: SFProDisplay;
    font-size: 10px;
    letter-spacing: 0.4px;
    color: $green;
    margin-top: .4em;

    &::before {
    	content: '';
    	display: inline-block;
    	background-color: $green;
    	width: 5px;
    	height: 5px;
    	position: relative;
    	top: -1px;
    	margin-right: 4px;
    }
	}

	&__title {
		font-family: RobotoSlab;
		// font-size: 20px;
		font-size: 19px;
		margin-top: .35em;
		line-height: 1.2;
		color: $black;
	}

	p {
		font-family: RobotoSlab;
		font-size: 16px;
		line-height: 1.35;
		letter-spacing: 0.7px;
		color: #2d2d2d;
		margin-top: 8px;
		
		+ p {
			margin-top: 12px;
		}
	}

	@include mq('tablet', max) {
		&-group {
			.news-card--wrapped {
				margin-top: 15px !important;
			}

			> .news-list {
				.news-card--wrapped {
					&:last-of-type {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

//

.margBtt-50 {
	margin-bottom: 50px;
}

.galeria-video {
	border-bottom: 1px solid $gray;
	padding-bottom: 20px;

	.news-card__info {
		padding: 6px 0 0;
	}
}

//

.contact-publicidad {
	color: darken($light-gray, 8%);

	.news-section {
		> p {
			margin-top: 20px;
		}
		h3 {
			font-size: 24px;
		}

		h4 {
    	font-size: 18px;
    	margin: 20px 0;
		}

		p {
			letter-spacing: 0.3px;
		}
	}
}



//


.columnst-intern {
	.columnists-shortcut__item {
		border-bottom: none;
	
		.descrp-autor {
			font-style: italic;
		  margin-bottom: 5px;
		}

		.fntSize-14 {
			font-size: 14px;
		}
	}

	.featured-news-main__tab-item-title  {
		text-transform: none;
	}
}

.content-autor {
	.featured-news-main__tab-item-title {
		font-weight: 100;
	}

	.news-media-description{
		p {
	    font-size: 10px;
	    margin-top: 8px;
		}
	}

	.news-text-content {
		letter-spacing: 0.7px;
    text-align: justify;
    margin: 25px 0;
	}

	.textual-quotation {
		margin: 0 15%;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.9px;
    text-align: justify;
    color: #17a72f;
    border-bottom: 2px solid #979797;
    border-top: 2px solid #979797;
    padding: 20px 0;
	}
}

//

.news-summary {
	h4 {
		font-weight: normal;
    margin-bottom: 5px;
    border-bottom: 2px solid #979797;
    padding-bottom: 10px;
	}
}



/* News internal styles */
.news {
	/* state save news */
	.save {
		display: block;
	}

	.saved {
		display: none;
	}

	&__head {

	}

	&__title {
		font-size: 30px;
		font-weight: bold;
		line-height: 1.3;
		margin-bottom: .6em;
	}

	&__summary {
		font-size: 16px;
		font-weight: normal;
		letter-spacing: .5px;
		line-height: 1.6;
	}

	&__bar-info {
		border-top: 1px solid $gray;
		margin-top: 10px;
		padding-top: 7px;
		margin-bottom: 15px;
		
		&-post {
			display: flex;

			p {
				margin: 0;	
			}
		}
		
		&-social {
			.social-simple {
				margin: 0;
			}
		}
	}

	&__date-pub {
		font-size: 12px;
		font-weight: bold;
		// min-width: 150px;
		display: flex;
		align-items: center;
		
		+ .news__date-pub {
			margin-left: 20px;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 14px;
			height: 14px;
			margin-right: 6px;

			svg {
				width: 14px;
				height: 14px;
				fill: $violet;
			}
		}
	}

	&__media {
		margin-bottom: 20px;

		&-format {
			img {
				@extend %center-block;
			}
		}

		&--video {
			width: 550px;
			max-width: 100%;
			@extend %center-block;

			.news__media-format {
				
			} 
		}

		&-description {
			padding: 7px 0 0;

			p {
				font-size: 10px;
			}
		}
	}

	&__audio {
		width: 750px;
		max-width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
		@extend %center-block;

		h5 {
			margin-bottom: .7em;
		}
	}

	&__content {
		> p {
			font-size: 16px;
	    line-height: 1.6;
	    letter-spacing: 0.5px;

	    &:last-of-type {
	    	margin-bottom: 25px;
	    }

	    a {
	    	font-weight: bold;
	    	color: $red-dark;
	    	text-decoration: underline;
	    }
		}

		ul {
			margin-top: 16px;

			li {
				position: relative;
				padding-left: 15px;
				line-height: 1.6;

				+ li {
					margin-top: 10px;
				}

				&::before {
					content: '•';
					position: absolute;
					left: 0;
					top: 1px;
					font-size: 20px;
					line-height: 1;
				}
			}
		}

		&-highlight {
			margin: 35px 0;

			&-wrap {
				padding: 20px 0;
				border-top: 1px solid $green;
				border-bottom: 1px solid $green;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: 0;
					height: 2px;
					background-color: $green;
					width: 100px;
					@include center-elm(true, false);
				}
			}


			p {
				font-size: 20px;
				font-weight: bold;
				color: $green;
			}
		}

		&-see-more {
			margin: 35px 0 25px;

			h4 {
				font-weight: bold;
		    letter-spacing: 0.7px;
		    color: $green;
		    margin-bottom: 10px;
			}

			a {
	    	color: lighten($black, 30%);
	    	text-decoration: underline;
	    }
		}

		&-aditional {
			h3 {
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 1.5em;
			}
		} 
	}

	&__related {
    border-top: 2px solid $gray;
    padding-top: 20px;
    padding-bottom: 20px;

    &-title {
    	font-size: 20px;
    	color: $purple;
    	letter-spacing: 0.9px;
    	margin-bottom: 1em;
    }
	}

	/* News media gallery image */
	&__gallery-image {
		margin-bottom: 15px;
		
		&-list {
			display: flex;
			flex-wrap: wrap;
			margin-left: -7px;
			margin-right: -7px;
		}

		&-item {
			padding: 7px;
			width: 25%;

			a {
				display: block;
				position: relative;

				&:hover {
					&::before {
						opacity: 1;
					}

					&::after {
						opacity: 1;
					}
				}
				
				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					background-color: rgba($green, .8);
					transition: all 200ms ease;
				}

				&::after {
					content: '';
					opacity: 0;
					background: url('../images/icons/search.png') center/100% no-repeat;
					width: 30px;
					height: 30px;
					transition: all 200ms ease;
					@include center-elm(true, true);
				}
			}
		}
	}


	/* News more gallery */
	&__more {
		background-color: lighten($gray, 5%);
		padding: 10px 0;
		margin-top: -19px;
		margin-bottom: 20px;

		&-gallery {
			.owl-carousel {
				.owl-nav {
					.owl-prev,
					.owl-next {
						position: absolute;
						top: -15px;
						height: calc(100% + 25px);
						width: 22px;
						background-color: $khaki;
						display: flex;
						align-items: center;
						justify-content: center;
				
						svg {
							fill: $white;
							width: 16px;
							height: 16px;
						}
					}

					.owl-prev {
						left: 0;
					}

					.owl-next {
						right: 0;
					}
				}
			}
		}

		.news-small {
			width: 260px;
			max-width: 260px;
		}
		
		@include mq('tablet-wide', max) {
			margin-top: -15px;
		}
	}
	
	/* Media queries */
	@include mq('tablet-wide', max) {
		&__bar-info {
			&-social {
				padding-top: 12px;

				.social-simple {
					justify-content: flex-start;
				}
			}
		}
	}

	@include mq('tablet', max) {
		&__title {
			font-size: 20px;
		}

		&__content {
			&-highlight {
				&-wrap {
					text-align: center;
				}
			}
		}

		&__bar-info {
			border-top: 0;

			&-post {
				position: relative;
				padding-bottom: 10px;
				flex-wrap: wrap;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 10px;
					height: 1px;
					background-color: $gray;
					width: calc(100% - 20px);
				}

				p {
					width: 100%;

					+ .news__date-pub {
						margin-left: 0;
						margin-top: 8px;
					}
				}
			}
		}

		&__gallery-image {
			&-item {
				width: 33.333%;
			}
		}
	}

	@include mq('phone-wide', max) {
		&__gallery-image {
			&-item {
				width: 50%;
			}
		}
	}
}

/* News small for gallery internal */
.news-small {
	background-color: $white;
	border-radius: 8px;

	&.gallery {
		.news-small__image {
			&::after {
				background-image: url('../images/home/i-galeria.png');
			}
		}
	}

	&.video {
		.news-small__image {
			&::after {
				background-image: url('../images/home/i-video.png');
			}
		}
	}

	&.audio {
		.news-small__image {
			&::after {
				background-image: url('../images/home/i-audio.png');
			}
		}
	}

	&__content {
		display: flex;
		align-items: flex-start;
		padding: 10px;
		text-decoration: none;
		color: lighten($black, 15%);

		&:hover {
			h2 {
				text-decoration: underline;
			}
		}

		h2 {
			font-size: 12px;
			font-weight: bold;
			margin-bottom: .35em;
			letter-spacing: 0.5px;
			line-height: 1.1;
			position: relative;
			top: -2px;
		}
	}

	&__image {
		width: 70px;
		height: 53px;
		overflow: hidden;
		flex: 1 0 auto;
		margin-right: 10px;
		position: relative;

		&::after {
			content: '';
			width: 25px;
			height: 25px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			@include center-elm(true, true);
		}
		
		img {
			position: absolute;
			left: -16%;
			max-width: initial;
			width: calc(100% + 22px) !important;
		}
	}

	&__date {
		display: block;
		font-size: 10px;
	}
}



.news-card__date-two {
	color: $black;
	margin-top: 10px;
  margin-bottom: 24px;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0.4px;
	display: block;
}

.is-saved {
	.save {
		display: none;
	}
	.saved {
		display: block;
	}
}


.title-interesting {
	font-size: 20px;
	letter-spacing: 0.9px;
	margin-bottom: 21px;
	border-top: 2px solid #979797;
	padding-top: 20px;
}

.social-columnist {
  justify-content: space-between;
  display: flex;

  ul {
  	display: flex;
  }
}

.textual-more  {
	display: block;
	margin: 0 15%;

	p {
		text-align: justify;
    color: #585858;
    margin-bottom: 25px;
	}
}

.link-green {
	font-weight: bold;
	letter-spacing: 0.7px;
	color: #17a72f;
	text-decoration: underline;
	margin-bottom: 10px;
}

.ctn-info-agrotv {
	h3 {
		color: #2d2d2d;
    margin-bottom: 20px;
    text-transform: uppercase;
	}
}

.tag {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin:20px 0;

	li {
		span {
			font-weight: bold;
    	margin-right: 20px;
		}

		.link-green {
			margin-right: 50px;
		}
	}

}

//

.card-bg-gray {
	background-color: #fbfbfb;
  border: 1px solid #f7f3f3;
  padding: 15px;
}

.tags {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin:20px 0;

	&__tag {
		span {
			font-weight: bold;
		}

		+ li {
			margin-left: 16px;
			margin-bottom: 5px;
		}
	}
}

.queen-detail__carousel.detail-oferts {
	overflow: hidden;

	.owl-carousel .owl-dots .owl-dot {
	  padding: 3px 3px 0px 3px;
	}
} 