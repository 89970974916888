.agronegocios, .agronegocios__ofertas-demandas, .agronegocios__movimientos-precios {
	.wrapper-agronegocios, .wrapper-agronegocios__ofertas-demandas, .wrapper-agronegocios__movimientos-precios {
    @include mq('tablet', min) {
    	
    	.o-container {

    		display: flex;
		    justify-content: space-between;
		    width: 	100%;
    		article {
    		 	width: 	835px;
    		}

    		aside {
	    		width: 265px;
    		}
    	}
    }
	}
}