.nav-inner {
	padding-top: 20px;
	padding-bottom: 20px;

	+ .main {
		padding-top: 0;
	}
	
	/* Media queries */
	@include mq('tablet-wide', max) {
		padding-top: 60px;
		padding-bottom: 15px;
	}
}

> .nav-inner {
	[class*="col-"] & {
		margin-left: -10px;
		margin-right: -10px;
		margin-top: -20px;
	}

	/* Media queries */
	@include mq('tablet-wide', max) {
		[class*="col-"] & {
			padding-top: 20px;
		}
	}
}


.breadcrumbs {
	font-style: italic;
	background-color: #a48405;
	display: inline-block;
	// margin-bottom: 20px;

	ul {
		padding: 5px 10px;
		font-size: 0;
	}

	li {
		display: inline-block;

		~ li {
			&::before {
				content: '/';
				color: $white;
				margin: 0 4px;
				font-size: 12px;
			}
		}

		&:last-of-type {
			a {
				font-weight: bold;
			}
		}
	}

	a {
		font-family: $font-secondary;
		color: $white;
		font-size: 10px;
		display: inline-block;
	}
}
