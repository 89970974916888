.trends {
  width: 100%;
  height: 50px;
  box-shadow: 0 3px 6px rgba($black, .1);
  background-color: #ebf5ed;
	
	.container {
		height: 100%;
		padding-right: 0;
    padding-left: 0;
	}

  &__content {
  	display: flex;
  	padding: 12px 0;
  	position: relative;

  	&::after {
  		content: '';
  		width: 30px;
  		height: 100%;
  		position: absolute;
  		right: 0;
  		top: 0;
  		background: linear-gradient(to right, rgba($white, 0) 0, rgba($white, 0.8) 60%, rgba($white, 1) 100%);
  		z-index: 1;
  	}
  }

	&__title {
		display: none;
		height: 40px;
		line-height: 40px;
		padding-right: 22px;

		h3 {
			font-family: $font-secondary;
			font-size: 16px;
			color: $white;
			text-transform: uppercase;
		}
	}

	&__gallery {
		width: 100%;
		overflow: hidden;
		background-color: #f3f3f3;

		.owl-carousel {
			.item {
				text-align: center;
				border-radius: 5px;
		    background-color: $green;
		    padding: 5px 7px;
				
				a {
					font-family: SFProDisplay;
					font-size: 14px;
					color: $white;
					display: block;
				}
			}

			.owl-stage-outer {
				z-index: 2;
			}

			.owl-nav {
				display: none;
			}

			.owl-prev, .owl-next {
				height: 20px;
			  width: 20px;
				@include center-elm(false, true);

				svg {
			    fill: $green;
				}
			}

			.owl-prev {
				right: calc(100% + 10px);
			}

			.owl-next {
				left: calc(100% + 10px);
			}
		}
	}

	@include mq('tablet', min) {
		background-image: linear-gradient(to right, $green, $green 50%, #f3f3f3 50%, #f3f3f3 100%);
		display: flex;
	  align-items: center;
	  justify-content: space-between;
	  padding: 0;
	  height: 40px;
	  background-color: $transparent;

	  .container {
	  	padding-right: 15px;
    	padding-left: 15px;
	  }

	  &__content {
	  	padding: 0;

	  	&::after {
	  		width: 0;
	  		height: 0;
	  		background: $transparent;
	  	}
	  }


	  &__title {
	  	display: inline-block;
	  }
		
		&__gallery {
			padding: 0 45px;

			.owl-carousel {
				.owl-stage-outer {
					// margin: 0 45px;
				}

				.item {
					border: 0;
			    background-color: transparent;
			    padding: 0;
			    height: 40px;
			    display: flex;
			    align-items: center;

			    a {
			    	font-size: 16px;
			    	color: $green;
			    }
				}

				.owl-nav {
				 	display: block;
			 	}
			}
		}
	}
}