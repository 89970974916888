.staff{
	&__section-main {
		width: 930px;
		max-width: 100%;
		margin: 30px auto 0;
	}

	&__content-pic {
		padding: 35px 0 20px;

		img {
			max-width: 260px;
			@extend %center-block;
		}
	}

	&__content-data {
		&__name{
			margin:0 0 5px;
			h2{
			  font-family: RobotoSlab;
			  font-size: 30px;
			  font-weight: bold;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: normal;
			  letter-spacing: 1.3px;
			  text-align: center;
			  color: $black;
			}
		}
		&__mail{
			margin:5px 0;
			h2{
				text-align: center;
			  font-family: RobotoSlab;
			  font-size: 16px;
			  font-weight: normal;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.38;
			  letter-spacing: 0.7px;
			  text-align: center;
			  color: $slow-dark;
			}
		}
		&__charge{
			margin: 5px 0;

			h2{
				text-align: center;
				font-family: RobotoSlab;
			  font-size: 16px;
			  font-weight: bold;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.38;
			  letter-spacing: 0.7px;
			  text-align: center;
			  color: $slow-dark;
			}
		}
	}

	&__content-description {
		padding-top: 30px;
		padding-bottom: 30px;

		p {
			font-family: RobotoSlab;
		  font-size: 16px;
		  font-weight: normal;
		  font-style: normal;
		  font-stretch: normal;
		  line-height: 1.63;
		  letter-spacing: 0.7px;
		  text-align: justify;
		  color: $slow-dark;
		}
	}
	
	/* Media queries */
	@include mq('tablet', max) {
		&__content-pic {
			padding: 0 0 20px;
		}
	}
}