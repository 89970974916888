/* ===========================
	 PLACEHOLDER
	 =========================== */

/* CLEARFIX */

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}


/* BACKGROUND IMAGE VALUES */

%bg-image {
	width: 100%;
	background-position: no-repeat;
	background-position: center center;
	background-size: cover;
}


/* CENTER ELEMENTS OF BLOCK */

%center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


/* FONT SMOOTHING */
%font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


/* BUTTON */
%btn {
	border: 0;
	outline: 0;
	cursor: pointer;
	text-align: center;
	// border-radius: 4px;
	box-shadow: none;
	display: inline-block;
	color: $white;
	transition: all 250ms ease;
	max-width: 100%;
	@extend %font-smoothing;
}

