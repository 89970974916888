.ciencia-tecnologica-grid {
	.news-list {
		height: 100%;
	}

	.news-card {
		display: flex;

		+ .news-card {
			margin-top: 20px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		.news-card__box {
	    flex: 0 0 50%;
	    max-width: 50%;

	    figure {
	    	max-height: 185px;
	    }
		}

		.news-card__text {
			background-color: #ececec;
			padding: 15px;

			h2 {
				a {
					color: $black;
				}
			}

			h3 {
				color: $black;
  			margin-bottom: 10px;
				
				&:before {
					background-color: $black;
				}
			}
		}
	}
	
	/* Media queries */
	@include mq('desktop', max) {
		.news-list {
			.news-card__box {
				display: none;
			}
		}
	}

	@include mq('tablet', max) {
		.news-list {
			margin-top: 10px;
		}

		.news-card {
			margin-bottom: 10px;

			&:last-of-type {
				margin-bottom: 20px;
			}
		}
	}
}

.jokes__item {
	margin: 10px 0;

	&__wrapper {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid $black;
		
		.jokes__item-content {
			border-bottom: 0px;
			flex: 1 1 auto;
		}
		
		.jokes__item-title {
			padding: 7px 0 0 0;
		}

		figure {
			margin-right: 20px;
			// width: 1200px;
			width: 320px;
			flex: 0 0 auto;
			margin-bottom: 20px;
		}
	}

	.news-card__share {
		right: 10px;
	}

	p {
		margin: 9px 0 4px;
	}

	.news-card__subtitle {
		color: $black;

		&:before {
			background-color: $black;
		}
	}
}