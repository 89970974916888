.queen-detail {

	.queen {
		border-top: 1px solid lighten($black, 50%);
	}

	/* TITLE */
	&__ubication {
		margin: 20px 0;
		h6 {
	  	font-family: SFProDisplay;
	  	font-size: 10px;
	  	font-style: italic;
	  	font-weight: 100;
	  	font-stretch: normal;
	  	line-height: normal;
	  	letter-spacing: 0.4px;
	  	text-align: left;
	  	padding: 6px;
			color: $white;
			background: $khaki;
			display: inline-block;

			span{
				font-weight: bold;
			}
		}
	}

	/* MAIN QUEEN IMAGE */
	&__section-main {
		margin-bottom: 23px;
	}

	&__content {
		//margin: 23px 0;
		&__pic{

			img{
			margin-bottom: 23px;
			}
		}
		/* SLIDER FOR MAIN IMAGE */
	}

	&__slider{
		margin: 20px 0;
		padding: 0;

		img{
			padding: 0;
		}
	}

	&__slider-img{
	}
	
	&__social-queen {
		margin-bottom: 24px;
	}

	/* QUEEN DATA */
	&__data-queen {
		h3 {
	 		font-size: 20px;
	  	font-weight: bold;
	  	font-stretch: normal;
	  	line-height: 1.2;
	  	letter-spacing: 0.5px;
	  	text-align: left;
	  	color: $black;
	  	margin-bottom: 8px;
		}

		h4 {
	  	font-size: 16px;
	  	font-weight: bold;
	  	line-height: 1.9;
	  	letter-spacing: 0.4px;
	  	text-align: left;
	  	color: $slow-dark;

	  	span{
	  		font-weight: normal;
	  	}
		}

		p {
	  	font-size: 16px;
			margin-top: 15px;
	  	line-height: 1.63;
	  	letter-spacing: 0.7px;
	  	color: $slow-dark;
		}
	}

	&__carousel {
		display: block; 
		width: 100%;
		margin: 0 auto;

		.owl-carousel {
			// div {
			// 	width: 100%;
			// }
			
			.owl-controls {
				.owl-dot {
					float: center; 
				}
			}

			.owl-dots {
				margin-left: -10px;
				margin-right: -10px;

				.owl-dot {
					float: left;
					padding: 20px 10px 0;

					span {
						background-color: $transparent !important;
						background-position: center center;
						background-size: cover;
						width: 100%;
						padding-bottom: 100%;
						height: 0 !important;
						border-radius: initial !important;
					}
				}
			}
		}
	}

	@include mq('tablet-wide', max) {
		&__carousel {
			.owl-carousel {
				.owl-dots {
					margin-left: -5px;
					margin-right: -5px;

					.owl-dot {
						padding: 10px 5px 0;
					}
				}
			}
		}
	}

	@include mq('tablet', max) {
		&__social-queen {
			margin: 12px 0 0;
		}

		&__data-queen {
			padding-top: 12px;
		}
	}
}

// MORE QUEENS
.queen {
	.title-more{
		margin: 20px 0;

		h2 {
	  font-family: RobotoSlab;
	  font-size: 20px;
	  font-weight: bold;
	  font-style: normal;
	  font-stretch: normal;
	  line-height: normal;
	  letter-spacing: 0.9px;
	  color: $black;
	  }
	}
}

