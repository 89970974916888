/* ===========================
   COLORS
   =========================== */

/* Highlight colors */
$blue-light2: #31b4bb;
$lightblue: #0acade;
$gray: #dbdbdb;
$light-gray: #979797;
$red: #d3000c;

/* Neutro colors */
$white: #FFF;
$black: #000;
$transparent: transparent;


//

$green: #16a730;
$green-light: #E5FFE7;
$green-extralight: #EDF4EE;
$red-dark: #c6000d;
$purple: #8341ab;
$gray-dark: #464646;
$blue-dark: #324a89;
$khaki: #a48405;
$slow-dark: #2d2d2d;
$violet: #501f66;
$orange: #e0690e;

$yellow: #fbcb0f;

