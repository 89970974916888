// REPORTE CAROUSEL
.reporter {
	.owl-dots {
		.owl-dot {
			span {
				margin: 5px 3px;
			}
		}
	}
	&__number-phone {
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			width: 25px;
			height: 25px;
			margin-right: 5px;
			fill: $green;
		}

		span {
			color: $green;
			font-family: RobotoSlab;
			font-size: 25px;
			font-weight: bold;
			text-align: center;
			display: block;
		}
	}
}


/**
 * FOLLOWUS SOCIAL
 */
.followus {
	&__item {
		&-link {
			margin-bottom: 15px;

			a {
				font-size: 14px;
				font-weight: normal;
				text-decoration: underline;
				color: lighten($black, 35%);
				display: block;
			}
		}
	}
}

/**
 * AGROPHRASE
 */
.agrophrase {
	padding: 25px 0 20px;
  background-color: #f3fbf5;
  text-align: center;

  &__title {
  	font-family: $font-secondary;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: lighten($black, 15%);
    margin-bottom: .5em;
  }

  &__gallery {
  	&-item {
  		p {
		    font-family: $font-secondary;
		    font-size: 24px;
		    font-weight: 500;
		    font-style: italic;
		    letter-spacing: 1.1px;
		    text-align: center;
		    color: lighten($black, 50%);
		    margin: 0 auto;
		    width: 685px;
		    max-width: 100%;
		    @extend %center-block;
  		}
  	}

  	.owl-dots {
  		margin-top: 8px;

			.owl-dot {
				span {
					width: 16px;
					height: 16px;
					margin: 5px 5px;
				}
			}
		}

		@include mq('tablet', max) {
			&-item {
				p {
					font-size: 18px;
				}
			}

			.owl-dots {
				.owl-dot {
					span {
						width: 14px;
						height: 14px;
						margin: 8px 4px;
					}
				}
			}
		}
  }
}

.control-nav-carousel__button {
	background-color: transparent;
}