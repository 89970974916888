.popupCustom {
  position: relative;
	margin: 15px auto;
	width: auto;
	background-color: $white;
}

.popupSuscription {
  max-width: 700px;
  border-radius: 10px;
  overflow: hidden;
  
  &__head {
    background: $green;
    text-align: center;
    padding: 20px 15px;

    h5 {
      font-size: 25px;
      font-weight: bold;
      color: $white;
    }
  }

  &__body {
    text-align: center;
    color: $gray-dark;
    padding: 45px 90px 65px;

    h6 {
      font-size: 65px;
      font-weight: normal;
      line-height: 1.1;
      margin-bottom: .325em;
    }

    p {
      font-size: 18px;
      line-height: 1.45;
    }

    table {
      width: 435px;
      max-width: 100%;
      border-collapse: collapse;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      margin-top: 2.5rem;
      margin-bottom: 2.25rem;

      td, th {
        border: 1px solid $white;
        padding: 8px 12px;
      }

      th {
        font-weight: bold;
        font-size: 15px;
        color: $white;
        background-color: $green;
      }

      td {
        font-size: 15px;
        background-color: #F6F4F0;
      }
    }

    .btn {
      font-size: 16px;
      text-transform: uppercase;
      color: $green;
      font-weight: bold;
      width: 250px;
      max-width: 100%;
      border: 1px solid $green;
      border-radius: 0;
      padding: .4em .7em;
    }
  }

  @include mq('tablet', max) {
    &__head {
      padding: 12px 10px;

      h5 {
        font-size: 18px;
      }
    }

    &__body {
      padding: 20px 15px 30px;

      h6 {
        font-size: 40px;
      }

      p {
        font-size: 16px;
      }

      table {
        width: 100%;

        th, td {
          font-size: 13px;
        }
      }
    }
  }
}