.o-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;

	//> [class*="o-col"],
	//> * {
		//padding-left: 15px;
		//padding-right: 15px;
	//}
}

.o-row-np {
	display: flex;
	flex-wrap: wrap;
}