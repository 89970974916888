.margin-top-25 {
  margin-top: 25px;
}

.page-bottom {
  overflow: hidden;
}

.newsletter {
	width: 100%;
	color: $white;
	padding: 35px 0;
  background-color: #dd0105;

  h3 {
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  p {
  	font-size: 18px;
    letter-spacing: 1.1px;
  }

  &__form {
    margin-top: 25px;
    width: 1035px;
    max-width: 100%;

    &-wrap {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      margin-left: -30px;
      margin-right: -30px;
    }

    .field {
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;

      input {
        font-family: $font-main;
        width: 100%;
        color: $white;
        border: 0;
        border-bottom: 1px solid $white;
        background-color: $transparent;
        padding: 9px 0;
        @include input-placeholder { color: $white; }
      }

      label.error {
        font-size: 12px;
      }

      &-group {
        width: 70%;
        padding-left: 30px;
        padding-right: 30px;

        > div {
          display: flex;
          flex-wrap: wrap;
          margin-left: -20px;
          margin-right: -20px;
        }
      }
    }
  }

  &__action {
    width: 30%;
    padding-left: 30px;
    padding-right: 30px;

    button {
      font-family: $font-main;
      font-weight: bold;
      font-size: 18px;
      color: $white;
      display: block;
      background-color: $transparent;
      border: 1px solid $white;
      width: 100%;
      padding: 10px 16px;
    }
  }

  @include mq('tablet', max) {
    h3 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }

    &__form {
      margin-top: 12px;
    }

    .field {
      input {
        font-size: 15px;
      }

      &-group {
        width: 100%;
      }
    }

    &__action {
      width: 100%;
      margin-top: 30px;

      button {
        font-size: 16px;
      }
    }
  }

  @include mq('phone-wide', max) {
    padding: 20px 0;

    .field {
      width: 100%;
      
      ~ .field {
        margin-top: 20px;
      }
    }
  }
}


.footer {
 color: $black;
  width: 100%;

  .o-container {
    .o-row {
      justify-content: space-around;
    }

    .wraper__info {
      font-family: SFProDisplay;
      width: 100%;
      text-align: center;

      .title__info {
        margin-bottom: 15px;
        justify-content: center;
        display: flex;
        align-items: center;

        &.is-open {
          svg {
            transform: rotate(90deg);
          }
        }

        h3 {
          font-size: 12px;
          text-decoration: underline;
          text-transform: uppercase;
          margin-right: 10px;
        }

        svg {
          width: 16px;
          height: 16px;
          transform: rotate(-90deg);
          transition: all 200ms ease;
        }
      }

      .link__info {
        display: flex;
        justify-content: center;        
        flex-direction: column;
        transition: .8s;
        max-height: 0;
        overflow: hidden;

        a {
          font-size: 9px;
          text-transform: uppercase;
          color: $black;
          margin-bottom: 5px;
          display: block;
        }
      }
    }
    
    //logo

    .footer-logo {
      width: 420px;
      margin-top: 25px;

      img {
        @extend %center-block;
      }
    }

    .ctn-info-footer {
      font-family: 'SFProDisplay';
      font-size: 10px;
      line-height: 1.6;
      letter-spacing: 0.5px;
      text-align: center;
      color: #8e8e8e;
      margin: 25px 0;
      width: 100%;
    }

    .text-condiciones {
      width: 100%;
      margin: 0 auto;
     
    }

    .info-contact {
      width: 100%;
      margin: 20px auto;

      p {
        a {
          color: #8e8e8e;
          display: block;
        }
      }
    }

    .wrapper-derechos-reservados {
      ul {
        justify-content: space-between;
        font-family: 'SFProDisplay';
        font-size: 10px;
        color: #8e8e8e;
        margin: 20px 0;
        font-weight: 600;

      }
    }

    .header__social {
      justify-content: center;
    }

    @include mq('tablet', max) {
      .wraper__info {
        .title__info {
          margin-top: 0;
          margin-bottom: 0;
          // padding: 16px 25px;
          position: relative;
          justify-content: space-between;
          border-bottom: 1px solid $gray;
          background-color: rgba($gray, .2);
          cursor: pointer;

          &.not-dropdown {
            svg {
              display: none;
            }
          }

          svg {
            position: absolute;
            right: 15px;
          }

          h3 {
            font-size: 14px;
            text-decoration: none;
            width: 100%;
            margin-right: 0;
            z-index: 1;

            a {
              display: block;
              padding: 16px 25px;
              text-align: left;
              cursor: pointer;
            }
          }
        }

        .link__info {
          &.show__menu__footer {
            padding: 10px  0;
            border-bottom: 1px solid $gray;
          }

          a {
            font-size: 12px;
            padding: 11px 10px;
            margin-bottom: 0;
          }
        }
      }

      .ctn-info-footer {
        margin: 0;
        padding: 0 10px;
      }

      .footer-logo {
        width: 250px;
      }
    }

    @include mq('phone-wide', max) {
      .wraper__info {
        .title__info {
          // padding: 16px 15px;
        }
      }

      .wrapper-derechos-reservados {
        ul {
          margin: 10px 0 20px;
        }
        li {
          text-align: center;
          width: 100%;
          + li {
            margin-top: 15px;
          }
        }
      }
    }
  }

  // Responsive phone
  @include mq('tablet', min) {

    .o-container  {
      .wraper__info {
        width: auto;

        .link__info {
          align-items: flex-start;
          max-height: initial;
        }

        .title__info {
          margin-top: 25px;
          justify-content: end;

          h3 {
            font-size: 10px;
            text-decoration: none;
          }

          svg {
            display:none;
          }
        }
      }

      .info-contact {
        width: 522px;
      }
    }
  }

  // responsive tablet 
  @include mq('tablet-wide', min) {
    .o-container {
      .text-condiciones {
        width: 825px;
      }
    }
  }
}

.show__menu__footer {
  max-height: 500px !important;
}

.scrollTop {
  display: none;

  @include mq('tablet', min) {
    position: fixed;
    right: 30px;
    bottom: 145px;
    background-color: $khaki;
    opacity: 0;
    z-index: 9;
    width: 74px;
    height: 74px;
    box-shadow: 2px 2px 6px 0 rgba(181, 173, 173, 0.5);
    border: solid 1px $light-gray;
    transition: all 0.4s ease-in-out 0s;
    display: block;

    svg {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      padding: 15px;
      fill: $white;

    }
  }
}

.newsletter-subscription {
  background-color: $black;
  position: fixed;
  right: 0;
  bottom: 50px;
  display: inline-block;
  z-index: 100;

  &__action {
    font-family: $font-secondary;
    font-weight: 200;
    color: $yellow;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: $transparent;
    width: 32px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .8;
    transition: opacity 150ms ease;
    @extend %btn;

    &:hover {
      opacity: 1;
    }

    > span {
      font-size: 40px;
      line-height: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 15px 45px 18px 15px;
  }

  &__image {
    margin-right: 10px;

    img {
      width: 35px;
    }
  }

  &__content {
    color: $yellow;

    span {
      font-size: 12px;
      margin-bottom: 5px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
