.wizard {
	display: block;
	// overflow: hidden;
	width: 100%;

	> .steps {
		> ul {
			display: flex;
			flex-wrap: wrap;
			width: 535px;
			@extend %center-block;

			> li {
				&:nth-of-type(1) {
					width: 29%;

					a {
						&::before {
							border-width: 0;

						}
					}
				}

				&:nth-of-type(2) {
					width: 34%;
				}

				&:nth-of-type(3) {
					width: 29%
				}

				+ li {
					margin-left: 4%;
				}
			}
		}

		.current-info {
			position: absolute;
			left: -9999px;
		}

		a {
			font-family: $font-secondary;
			font-size: 16px;
			font-weight: normal;
			color: $white;
			display: block;
			padding: 0 .5em;
			letter-spacing: -0.25px;
			text-align: center;
			height: 30px;
			line-height: 30px;
			position: relative;
			z-index: 2;

			&::before {
				content: '';
				border-width: 15px;
				border-style: solid;
				border-color: darken($gray, 15%);
				border-left-color: $transparent;
				position: absolute;
				top: 0;
				right: calc(100% - 15px);
				z-index: 1;
			}
			
			&::after {
				content: '';
				border-width: 15px;
				border-style: solid;
				border-color: $transparent;
				border-left-color: darken($gray, 15%);
				position: absolute;
				top: 0;
				left: 100%;
				z-index: 1;
			}

			.number {
				z-index: 2;
				position: relative;
			}
		}

		.current {
			a {
				background-color: $green;

				&::before {
					border-color: $green;
					border-left-color: $transparent;
				}

				&::after {
					border-left-color: $green;
				}
			}
		}
		
		.disabled {
			a {
				background: darken($gray, 15%);
			}
		}

		.done {
			a {
				background-color: lighten($green, 15%);

				&::before {
					border-color: lighten($green, 15%);
					border-left-color: $transparent;
				}

				&::after {
					border-left-color: lighten($green, 15%);
				}

			}
		}

		@include mq('tablet-small', max) {
			> ul {
				width: initial;
				max-width: 100%;
				justify-content: center;

				> li {
					+ li {
						margin-left: 2%;
					}	
				}
			}

			a {
				font-size: 14px;
				padding: 10px 10px;
				height: initial;
				border-radius: 6px;

				&::before,
				&::after {
					width: 0;
					height: 0;
					border: 0;
					background-color: $transparent;
				}

				> span {
					display: block;
					line-height: 1;

					&.number {
						margin-bottom: 4px;
					}
				}
			}
		}
	}

	> .content {
		> .title {
			position: absolute;
			left: -9999px;
		}

		> .body {
			// float: left;
			// position: absolute;
			// width: 95%;
			// height: 95%;
			// padding: 2.5%;
		}
	}

	> .actions {
		margin-top: 45px;
		margin-bottom: 15px;

		ul {
			display: flex;
			justify-content: space-between;

			a {
				font-family: $font-secondary;
				font-size: 16px;
				background-color: $green;
				color: $white;
				min-width: 170px;
				padding: 10px 12px;
				display: inline-block;
				border-radius: 6px;
				text-align: center;

				&.is-disabled {
					opacity: .4;
					pointer-events: none;			
				}
			}
		}

		@include mq('tablet-small', max) {
			ul {
				a {
					min-width: 100px;
				}
			}
		}
	}
}



// .wizard,
// .tabcontrol
// {
// 	display: block;
// 	width: 100%;
// 	overflow: hidden;
// }

// .wizard a,
// .tabcontrol a
// {
// 	outline: 0;
// }

// .wizard ul,
// .tabcontrol ul
// {
// 	display: flex;
// }

// .wizard ul > li,
// .tabcontrol ul > li
// {
// 	display: block;
// 	padding: 0;
// }

// /* Accessibility */
// .wizard > .steps .current-info,
// .tabcontrol > .steps .current-info
// {
// 	position: absolute;
// 	left: -999em;
// }

// .wizard > .content > .title,
// .tabcontrol > .content > .title
// {
// 	position: absolute;
// 	left: -999em;
// }



// /*
// 	Wizard
// */

// .wizard > .steps {
// 	position: relative;
// 	display: block;
// 	width: 100%;
// }

// .wizard.vertical > .steps
// {
// 	display: inline;
// 	float: left;
// 	width: 30%;
// }

// .wizard > .steps .number
// {
// 	font-size: 1.429em;
// }

// .wizard > .steps > ul > li
// {
// 	width: 25%;
// }

// .wizard > .steps > ul > li,
// .wizard > .actions > ul > li
// {
	
// }

// .wizard.vertical > .steps > ul > li
// {
// 	float: none;
// 	width: 100%;
// }

// .wizard > .steps a,
// .wizard > .steps a:hover,
// .wizard > .steps a:active
// {
// 	display: block;
// 	width: auto;
// 	margin: 0 0.5em 0.5em;
// 	padding: 1em 1em;
// 	text-decoration: none;

// 	-webkit-border-radius: 5px;
// 	-moz-border-radius: 5px;
// 	border-radius: 5px;
// }

// .wizard > .steps .disabled a,
// .wizard > .steps .disabled a:hover,
// .wizard > .steps .disabled a:active
// {
// 	background: #eee;
// 	color: #aaa;
// 	cursor: default;
// }

// .wizard > .steps .current a,
// .wizard > .steps .current a:hover,
// .wizard > .steps .current a:active
// {
// 	background: #2184be;
// 	color: #fff;
// 	cursor: default;
// }

// .wizard > .steps .done a,
// .wizard > .steps .done a:hover,
// .wizard > .steps .done a:active
// {
// 	background: #9dc8e2;
// 	color: #fff;
// }

// .wizard > .steps .error a,
// .wizard > .steps .error a:hover,
// .wizard > .steps .error a:active
// {
// 	background: #ff3111;
// 	color: #fff;
// }

// .wizard > .content
// {
// 	background: #eee;
// 	display: block;
// 	margin: 0.5em;
// 	min-height: 35em;
// 	overflow: hidden;
// 	position: relative;
// 	width: auto;

// 	-webkit-border-radius: 5px;
// 	-moz-border-radius: 5px;
// 	border-radius: 5px;
// }

// .wizard.vertical > .content
// {
// 	display: inline;
// 	float: left;
// 	margin: 0 2.5% 0.5em 2.5%;
// 	width: 65%;
// }

// .wizard > .content > .body
// {
// 	float: left;
// 	position: absolute;
// 	width: 95%;
// 	height: 95%;
// 	padding: 2.5%;
// }

// .wizard > .content > .body ul
// {
// 	list-style: disc !important;
// }

// .wizard > .content > .body ul > li
// {
// 	display: list-item;
// }

// .wizard > .content > .body > iframe
// {
// 	border: 0 none;
// 	width: 100%;
// 	height: 100%;
// }

// .wizard > .content > .body input
// {
// 	display: block;
// 	border: 1px solid #ccc;
// }

// .wizard > .content > .body input[type="checkbox"]
// {
// 	display: inline-block;
// }

// .wizard > .content > .body input.error
// {
// 	background: rgb(251, 227, 228);
// 	border: 1px solid #fbc2c4;
// 	color: #8a1f11;
// }

// .wizard > .content > .body label
// {
// 	display: inline-block;
// 	margin-bottom: 0.5em;
// }

// .wizard > .content > .body label.error
// {
// 	color: #8a1f11;
// 	display: inline-block;
// 	margin-left: 1.5em;
// }

// .wizard > .actions
// {
// 	position: relative;
// 	display: block;
// 	text-align: right;
// 	width: 100%;
// }

// .wizard.vertical > .actions
// {
// 	display: inline;
// 	float: right;
// 	margin: 0 2.5%;
// 	width: 95%;
// }

// .wizard > .actions > ul
// {
// 	display: inline-block;
// 	text-align: right;
// }

// .wizard > .actions > ul > li
// {
// 	margin: 0 0.5em;
// }

// .wizard.vertical > .actions > ul > li
// {
// 	margin: 0 0 0 1em;
// }

// .wizard > .actions a,
// .wizard > .actions a:hover,
// .wizard > .actions a:active
// {
// 	background: #2184be;
// 	color: #fff;
// 	display: block;
// 	padding: 0.5em 1em;
// 	text-decoration: none;

// 	-webkit-border-radius: 5px;
// 	-moz-border-radius: 5px;
// 	border-radius: 5px;
// }

// .wizard > .actions .disabled a,
// .wizard > .actions .disabled a:hover,
// .wizard > .actions .disabled a:active
// {
// 	background: #eee;
// 	color: #aaa;
// }

// .wizard > .loading
// {
// }

// .wizard > .loading .spinner
// {
// }



// /*
// 	Tabcontrol
// */

// .tabcontrol > .steps
// {
// 	position: relative;
// 	display: block;
// 	width: 100%;
// }

// .tabcontrol > .steps > ul
// {
// 	position: relative;
// 	margin: 6px 0 0 0;
// 	top: 1px;
// 	z-index: 1;
// }

// .tabcontrol > .steps > ul > li
// {
// 	float: left;
// 	margin: 5px 2px 0 0;
// 	padding: 1px;

// 	-webkit-border-top-left-radius: 5px;
// 	-webkit-border-top-right-radius: 5px;
// 	-moz-border-radius-topleft: 5px;
// 	-moz-border-radius-topright: 5px;
// 	border-top-left-radius: 5px;
// 	border-top-right-radius: 5px;
// }

// .tabcontrol > .steps > ul > li:hover
// {
// 	background: #edecec;
// 	border: 1px solid #bbb;
// 	padding: 0;
// }

// .tabcontrol > .steps > ul > li.current
// {
// 	background: #fff;
// 	border: 1px solid #bbb;
// 	border-bottom: 0 none;
// 	padding: 0 0 1px 0;
// 	margin-top: 0;
// }

// .tabcontrol > .steps > ul > li > a
// {
// 	color: #5f5f5f;
// 	display: inline-block;
// 	border: 0 none;
// 	margin: 0;
// 	padding: 10px 30px;
// 	text-decoration: none;
// }

// .tabcontrol > .steps > ul > li > a:hover
// {
// 	text-decoration: none;
// }

// .tabcontrol > .steps > ul > li.current > a
// {
// 	padding: 15px 30px 10px 30px;
// }

// .tabcontrol > .content
// {
// 	position: relative;
// 	display: inline-block;
// 	width: 100%;
// 	height: 35em;
// 	overflow: hidden;
// 	border-top: 1px solid #bbb;
// 	padding-top: 20px;
// }

// .tabcontrol > .content > .body
// {
// 	float: left;
// 	position: absolute;
// 	width: 95%;
// 	height: 95%;
// 	padding: 2.5%;
// }

// .tabcontrol > .content > .body ul
// {
// 	list-style: disc !important;
// }

// .tabcontrol > .content > .body ul > li
// {
// 	display: list-item;
// }