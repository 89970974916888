/* ===========================
   DROPDOWN
   =========================== */

.dropdown {
	position: relative;
	cursor: pointer;

	&-menu {
		font-family: $font-secondary;
		display: none;
    background-color: $white;
    position: absolute;
    left: 0;
    overflow: hidden;
    z-index: 30;
	}
}