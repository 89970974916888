/* ===========================
	 HEADER
	 =========================== */

.header {
	box-shadow: 0 2px 5px rgba($black, .25);
	z-index: 100;
	position: relative;

	&__row {
		font-family: $font-secondary;
	}

	&__advertising {
		height: 130px;
    width: 100%;
    background-image: url('../images/home/publicidad-superior.jpg');
    background-repeat: no-repeat;
    background-size: cover;
   	background-position: center;
   	display: none;

   	@include mq('tablet', min) {
   		display: block;
   	}
	}

	&__data, &__social {
		display: flex;
		margin: 8px 0;

		a {
			font-size: 10px;
  		color: black;
		}
	}

	&__data {
		&-item {
			~ .header__data-item {
				margin-left: 22px;
			}
		}

		&-icon {
			background-image: url('../images/sprite.png');
			background-repeat: no-repeat;
			background-size: 106px 71px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;

			.calendar & {
				width: 17px;
				height: 18px;
				background-position: -84px -25px;
			}

			.weather & {
				width: 18px;
				height: 18px;
				background-position: -85px -2px;
			}

			.magazine & {
				width: 14px;
				height: 17px;
				background-position: -84px -48px;
			}
		}
		&-text {
			font-size: 11px;
		}
	}

	&__social {
		justify-content: flex-end;
	}

	&__language {
		justify-content: flex-end;
		text-align: center;

		&-content {
			position: relative;
			display: inline-block;
		}
		
		&-btn {
			height: 27px;
			border-radius: 0 0 5px 5px;
			border: 1px solid $light-gray;
			font-size: 12px;
			background-color: $white;
			border-top: 0;
			padding: 1px 20px 1px 6px;
			position: relative;
			cursor: pointer;
			min-width: 115px;
			// box-shadow: 0 0 2px 1px rgba($black, .075);
			
			&.is-open {
				border-bottom-color: $transparent;
				border-radius: 0;

				&::before {
					right: 12.5px;
					transform: translateY(-50%) rotate(45deg);
				}
				
				&::after {
					right: 8px;
					transform: translateY(-50%) rotate(-45deg);
				}
			}
			
			&::before,
			&::after {
				content: '';
				background-color: $black;
				height: 6.5px;
				width: 1px;
				position: absolute;
				top: calc(50% - 0.5px);
			}

			&::before {
				right: 12.5px;
				transform: translateY(-50%) rotate(-45deg);
			}
			
			&::after {
				right: 8px;
				transform: translateY(-50%) rotate(45deg);
			}

			.language-current {
				font-weight: bold;
			}
		}

		&-list {
			border-radius: 0 0 5px 5px;
			border: 1px solid $light-gray;
			border-top: 0;
			text-align: center;
			top: calc(100% - 1px);
			width: 100%;
			
			li {
				font-size: 12px;
				font-weight: 500;
				text-decoration: underline;
				cursor: pointer;
				padding: 4px 0;
			}
			// box-shadow: 0 0 2px 1px rgba($black, .075);
		}
	}

	&__action {
		display: flex;
		align-items: center;
  	justify-content: flex-start;
  	position: relative;
  	top: -15px;

		&-item {
			button,
			a {
				font-size: 14px;
				padding: 0 .65em;
				letter-spacing: 0.5px;
				color: $white;
				height: 30px;
				border-radius: 5px;
				background-color: #d3000c;
 				border: 0;
 				text-transform: uppercase;
 				margin-right: 15px;
 				display: inline-block;
 				line-height: 30px;
 				text-align: center;
			}
		}
	}

	&__logo {
		width: 419px;
		margin: 10px 0;
		text-align: center;

		&-link {
			display: inline-block;
			width: 420px;
			height: auto;
		}
	}

	&__account {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		position: relative;
		top: -15px;

		&-login {
			display: flex;
		}

		&-loggedin {
			position: relative;

			&-btn {
				font-family: $font-secondary;
				font-size: 12px;
				font-weight: 500;
				color: $black;
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: 150px;
				max-width: 100%;
				padding: 4px 10px;
				@extend %btn;
				background-color: transparent;

				&.is-open {
					&::after {
						transform: translateY(-8px) rotate(180deg);
					}
				}

				&.is-man {
					&::before {
						background-image: url('../images/icons/user-man.png');
					}
				}

				&.is-woman {
					&::before {
						background-image: url('../images/icons/user-woman.png');
					}
				}

				&::before {
					content: '';
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					width: 14px;
					height: 17px;
					margin-right: 10px;
					position: relative;
    			top: -1px;
				}

				&::after {
					content: '';
					border-width: 8px 5px;
					border-style: solid;
					border-color: $transparent;
					border-top-color: $green;
					display: block;
					position: relative;
					top: 4px;
					margin-left: 10px;
				}

				.icon {
					margin-right: 10px;
				}
			}

			&-options {
				border-radius: 6px;
				width: 100%;
				box-shadow: 0 0 4px 2px rgba($black, .1);

				li {
					a {
						font-size: 14px;
						font-weight: 500;
						color: lighten($black, 25%);
						padding: 7px 10px;
						display: block;
						transition: all 200ms ease;

						&:hover {
							background-color: lighten($gray, 10%);
						}
					}
				}
			}
		}

		&-item {
			font-family: SFProDisplay;
			cursor: pointer;

			~ .header__account-item {
				margin-left: 15px;
			}
		}

		&-icon {
			display: inline-block;
			vertical-align: middle;
			margin-right: 6px;

			svg {
				fill: $green;
				width: 19px;
				height: 18px;
			}
		}

		&-msg {
			font-size: 12px;
			display: inline-block;
		}
	}

	&__site-nav {
		position: relative;
		padding-top: 40px;
		margin-top: 5px;
		transition: all 200ms ease;

		&-content {
			position: absolute;
			top: 0;
			width: 100%;
			background-color: $white;
			z-index: 20;

			> .container {
				max-width: 100% !important;
				height: 100%;
			}

			&.is-sticky {
				width: 100%;
				position: fixed;
				left: 0;
				top: 0;
				box-shadow: 0 1px 5px rgba($black, .1);

				.header__site-nav {
					&-logo {
						opacity: 1;
						transform: scale(1);
					}

					&-actions {
						opacity: 1;
						transform: scale(1);
					}
				}

				.submenu {
					box-shadow: 0 1px 1px 0 rgba($black, .1);

					@include mq('tablet-wide', max) {
						display: none !important;
					}

					&--clicked {
						padding: 6px 0 4px;
					}

					&__title {
						font-size: 0;
						padding: 0;
					}
				}
			}
		}

		&-wrap {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&-logo {
			opacity: 0;
			display: block;
			margin-right: 10px;
			transform: scale(0);
			transition: all 300ms ease;

			a {
				display: block;

				img {
					width: 140px;
				}
			}
		}

		&-actions {
			opacity: 0;
			display: flex;
			align-items: center;
			// margin-left: 10px;
			transform: scale(0);
			transition: all 300ms ease;

			li {
				+ li {
					margin-left: 13px;
				}

				span {
					line-height: 0;
					margin: 0;
					display: block;
				}

				.btn {
					font-size: 11px;
					padding: .45em .7em;
				}

				.clima {
					svg {
						fill: #ffcd00;
					}
				}

				svg {
					width: 18px;
					height: auto;
				}
			}
		}

		&-main {
			width: 1120px;
			border-top: 1px solid black;
		}

		&-list {
			width: 100%;
			justify-content: center;
	    display: flex;
	    align-items: center;
	    text-align: center;
	    // position: relative;
		}

		&-item {
			flex: 1 0 auto;
			height: 40px;

			&::before,
			&::after {
				content: "";
				display: block;
				height: 6px;
				width: 100%;
			}

			&::after {
				height: 0;
			}

			&.is-open-hover {
				> .submenu--hover {
  				display: block;
  			}
			}

			&.is-open-clicked {
  			.submenu--clicked {
					display: block;
  			}

  			&:hover {
  				.submenu--hover {
  					display: none !important;
  				}
  			}
  		}

			&:nth-child(1) {
				&::before,
				&::after {
				  background-color: #b49500;
	    	}

	    	a {
    			&:hover {
	    			background-color: #b49500;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #b49500;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #b49500;
	    		}

	    		&__card-title {
	    			background-color: rgba(#b49500, .8);
	    		}
	    	}
			}

			&:nth-child(2) {
				&::before,
				&::after {
					background-color: #16a730;
	    	}

	    	a {
    			&:hover {
	    			background-color: #16a730;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #16a730;
	    			color: $white;	
    			}
    		}

    		&.is-open-clicked {
    			a {
    				background-color: #16a730;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #16a730;
	    		}

	    		&__card-title {
	    			background-color: rgba(#16a730, .8);
	    		}
	    	}
			}

			&:nth-child(3) {
				&::before,
				&::after {
				  background-color: #0a2b70;
	    	}
	    	
	    	a {
    			&:hover {
	    			background-color: #0a2b70;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #0a2b70;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #0a2b70;
	    		}

	    		&__card-title {
	    			background-color: rgba(#0a2b70, .8);
	    		}
	    	}
			}

			&:nth-child(4) {
				&::before,
				&::after {
				  background-color: #c90505;
	    	}

    		a {
    			&:hover {
	    			background-color: #c90505;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #c90505;
	    			color: $white;
    			}
				}
				
				&.is-open-clicked {
    			a {
    				background-color: #c90505;
	    			color: $white;	
    			}
    		}

    		.submenu {
	    		&--clicked {
						background-color: #c90505;
	    		}

	    		&__card-title {
	    			background-color: rgba(#c90505, .8);
	    		}
	    	}
			}

			&:nth-child(5) {
				&::before,
				&::after {
				  background-color: #31b4bb;
	    	}

	    	a {
    			&:hover {
	    			background-color: #31b4bb;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #31b4bb;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #31b4bb;
	    		}

	    		&__card-title {
	    			background-color: rgba(#31b4bb, .8);
	    		}
	    	}
			}

			&:nth-child(6) {
				&::before,
				&::after {
					background-color: #643079;
	    	}

	    	a {
    			&:hover {
	    			background-color: #643079;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #643079;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #643079;
	    		}

	    		&__card-title {
	    			background-color: rgba(#643079, .8);
	    		}
	    	}
			}

			&:nth-child(7) {
				&::before,
				&::after {
				  background-color: #585858;
	    	}

	    	a {
    			&:hover {
	    			background-color: #585858;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #585858;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #585858;
	    		}

	    		&__card-title {
	    			background-color: rgba(#585858, .8);
	    		}
	    	}
			}

			&:nth-child(8) {
				&::before,
				&::after {
				  background-color: #e0690e;
	    	}

	    	a {
    			&:hover {
	    			background-color: #e0690e;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #e0690e;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #e0690e;
	    		}

	    		&__card-title {
	    			background-color: rgba(#e0690e, .8);
	    		}
	    	}
			}

			&:nth-child(9) {
				&::before,
				&::after {
				  background-color: #324a89;
	    	}

	    	a {
    			&:hover {
	    			background-color: #324a89;
	    			color: $white;
    			}
    		}

    		&.is-open-hover {
    			a {
    				background-color: #324a89;
	    			color: $white;	
    			}
    		}

	    	.submenu {
	    		&--clicked {
						background-color: #324a89;
	    		}

	    		&__card-title {
	    			background-color: rgba(#324a89, .8);
	    		}
	    	}
			}

			> a {
				font-family: 'RobotoSlab';
				font-size: 14px;
				text-transform: uppercase;
				color: black;
				display: block;
				padding: .5em 0 .7em;
				transition: all 100ms ease;
				display: flex;
		    justify-content: center;
		    align-items: center;
		    height: calc(100% - 6px);
			}
    }

    &-highlight {
    	@include mq('tablet-wide') {
    		display: none;
    	}
    }
	}

	&__btn-collapsator {
		height: 45px;
		background-color: $red-dark;
		width: 45px;
		padding: 0 11px;
		display: flex;
		@extend %btn;
		
		> span {
			width: 100%;
			height: 3px;
			background-color: $white;
			position: relative;
			
			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				background-color: $white;
				height: 100%;
				width: 100%;
				display: block;
				transition: all 150ms ease;
			}

			&::before {
				transform: translateY(-8px)
			}

			&::after {
				transform: translateY(8px)
			}
		}
	}

	&__highlight {
		&-trends {
			margin-top: 20px;

			.trends__gallery {
				background-color: $green-light;
			}
		}

		&-subscribe {
			margin: 20px 0;
			padding: 0 10px;

			a {
				width: 100%;
				margin: 0;
			}
		}

		&-social {
			.social-simple {
				flex-wrap: wrap;

				&__item {
					flex-basis: 1;
					flex-grow: 1;

					+ li {
						margin: 0;
					}

					&:last-of-type {
						flex: 100%;
						max-width: 100%;

						a {
							margin-top: 18px;
						}
					}

					a {
						span {
							font-size: 16px;
							margin-right: 10px;
						}
					}
				}
			}
		}
	}

	/* Media queries */
	@include mq('desktop', max) {
		&__site-nav {
			&-item {
				a {
					font-size: 11px;
				}
			}
		}
	}

	@include mq('tablet-wide', max) {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		z-index: 110;

		&__action {
			&-item {
				button,
				a {
					font-family: $font-secondary;
				}
			}
		}

		> .container {
			display: none;
		}

		&__site-nav {
			margin-top: 0;
			padding-top: 0;

			&-content {
				height: 45px;
				position: initial;
				top: auto;

				> .container {
					padding-left: 10px !important;
					padding-right: 10px !important;
				}
			}

			&-main {
				// display: none;
				position: fixed;
				top: 45px;
				left: 0;
				height: calc(100vh - 45px);
				width: 100%;
				background-color: $green-light;
				border-top: 0;
				transition: all 200ms ease;
				transform: translateX(100%);
				overflow: auto;
				padding: 0 0 25px;
			}

			&-list {
				flex-wrap: wrap;
			}

			&-item {
				flex: 100%;
				max-width: 100%;
				height: 36px;

				&::before {
					height: 0;
				}

				&::after {
					height: 3px;
				}

				> a {
					background-color: $green-light;
					font-size: 12px;
					font-weight: bold;
					color: #2D2D2D;
					height: calc(100% - 3px);
				}
			}

			&-wrap {
				justify-content: space-between;
			}

			&-logo,
			&-actions {
				opacity: 1;
				transform: scale(1);
			}

			&-actions {
				margin-right: -10px;
				
				li {
					height: 45px;

					+ li {
						margin-left: 0;
					}

					> span,
					> a {
						height: 100%;
						display: flex;
						align-items: center;
						padding: 0 11px;
					}
				}
			}
		}
	}

	@include mq('tablet-small', max) {
		&__site-nav {
			&-item {
				> a {
					padding: .5em 1.1em .7em;
					justify-content: flex-start;
				}
			}
		}
	}
}

/* =============
 	 SUBMENU
	 ============= */
.submenu {
	width: 100%;
	z-index: 20;
	text-align: left;
		
	&--clicked {
		background-color: $transparent;
		z-index: 1;
	}

	&--hover {
		z-index: 2;
		
		.submenu__content {
			background-color: $white;
			width: 1110px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__content {
		width: 100%;
	}

	&__title {
		font-size: 30px;
		color: $white;
		letter-spacing: .5px;
		padding: 18px 0 12px;
		text-transform: uppercase;
		transition: all 200ms ease;
  }
	
	&__list {
		.owl-carousel {
			.owl-stage {
				display: flex;
			}
		}

		a {
			font-family: $font-secondary;
			font-size: 14px;
			letter-spacing: .25px;
			text-transform: uppercase;
			color: $white;
			font-weight: 600;
			position: relative;
			padding: 7px 0;
			display: block;

			&::after {
				content: '';
				background-color: $transparent;
				height: 4px;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				transition: all 100ms linear;
			}

			&:first-of-type {
				padding-left: 0;
			}

			&:hover,
			&.is-active {
				&::after {
					background-color: $white;
				}
			}
		}
	}

	&__gallery {
		background-color: $white;
		padding: 20px 35px 15px;

		.owl-carousel {
			.owl-nav {
				.owl-prev,
				.owl-next {
					width: 24px;
					height: 24px;
					@include center-elm(false, true);

					svg {
						fill: #A48405;
					}
				}

				.owl-prev {
					right: calc(100% + 6px);
				}

				.owl-next {
					left: calc(100% + 6px);
				}
			}
		}
	}

	&__card {
		display: inline-block;
		position: relative;

		&-title {
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: inherit;
			min-width: 140px;
			max-width: 100%;
			z-index: 1;

			h4 {
				font-size: 12px;
				font-weight: bold;
				padding: .6em .5em;
				color: $white;
				text-transform: uppercase;
			}
		}
	}

	@include mq('tablet-wide') {
		position: absolute;
		top: 100%;
		left: 0;
		display: none;
	}

	@include mq('tablet-wide', max) {
		margin-bottom: 15px;

		&--clicked,
		&--hover {
			display: none;
		}

		&.submenu--mustard {
			background-color: #b49500;
		}

		&.submenu--green {
			background-color: $green;
		}
		
		&.submenu--blue-dark {
			background-color: #0a2b70;
		}

		&.submenu--red {
			background-color: $red-dark;
		}

		&.submenu--lightblue {
			background-color: $blue-light2;
		}

		&.submenu--purple {
			background-color: #643079;
		}

		&.submenu--gray {
			background-color: #585858;
		}

		&.submenu--orange {
			background-color: $orange;
		}

		&.submenu--blue {
			background-color: $blue-dark;
		}

		&__title {
			font-size: 25px;
		}
	}

	@include mq('tablet', max) {
		&__title {
			font-size: 20px;
			padding: 12px 0 10px;
		}
	}
}


/* Show search mobile */
.is-show-search-mobile {
	.search-mobile {
		display: block;
	}

	.search-btn__icon {
		background-color: $green-light;
	}
}

.is-show-nav-mobile {
	overflow: hidden;

	.header__btn-collapsator {
		span {
			background-color: $transparent;

			&::before {
				transform: translateY(0) rotate(-45deg);
			}

			&::after {
				transform: translateY(0) rotate(45deg);
			}
		}
	}

	.header__site-nav-main {
		transform: translateX(0);
	}
}

.is-show-account-options-mobile {
	.account-mobile {
		display: block;
	}

	.header__account-icon {
		background-color: $green-light;
	}
}