.not-found {
  font-family: $font-secondary;
  min-height: 100vh;
  background-color: #3D4158;
  color: $white;
  padding: 0;

  &__number {
    font-weight: bold;
    font-size: 180px;
  }

  &__info {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    h1 {
      font-size: 25px;
      font-weight: normal;
      letter-spacing: 0.5px;
      margin-bottom: .75em;
      margin-top: 0.25em;
    }

    p {
      span {
        display: block;
      }
    }
  }

  &__cta {
    margin-top: 50px;

    a {
      font-size: 20px;
      color: $white;
      background-color: $green;
      padding: .75em 1em;
      border-radius: 30px;
      @extend %btn;

      &:hover {
        background-color: lighten($green, 5%);
      }
    }
  }

  &__image {
    display: flex;
    height: 100%;
    align-items: flex-start;

    img {
      width: 400px;
      max-width: 100%;
      @extend %center-block;
    }
  }

  @include mq('tablet', max) {
    &__number {
      font-size: 120px;
    }
    
    &__cta {
      a {
        font-size: 16px;
      }
    }
  }
}