/* ===========================
	 MIXINS
	 =========================== */

/* MEDIA QUERIES */
// usage example: @include mq('name-breakpoint') { propiedad: valor; }

@mixin mq($width, $type: min) {
	@if map_has_key($breakpoints, $width) {
		$width: map_get($breakpoints, $width);
		@if $type == max {
			 $width: $width - 1px;
		}
		@media only screen and (#{$type}-width: $width) {
			@content;
		}
	}
}


/* Z-INDEX POSITION */
// usage example: z-index: z('header');

@function z($name) {
	@if index($z-indexes, $name) {
		@return (length($z-indexes) - index($z-indexes, $name)) + 1;
	} @else {
		@warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
		@return null;
	}
}
$z-indexes: (
	"outdated-browser",
	"modal",
	"navbar-fullscreen",
	"header",
	"upper-element",
	"main",
	"header"
);


/* CENTER ELEMENT */
// usage example: @include center-elm(true, true);

@mixin center-elm($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}


/* TRANSITION INITIAL */

@mixin transition-elm-ini($hide:true,$transition:all 0.5s ease-out) {
  @if ($transition) {
    transition: $transition;
  }
  @if ($hide) {
    opacity: 0;
    visibility: hidden;
  }
  @content;
}


/* TRANSITION END */

@mixin transition-elm-end($selector:'&.active',$hide:true) {
  @if ($selector) {
    #{$selector} {
      @if ($hide) {
        opacity: 1;
        visibility: visible;
      }
      @content;
    }
  } @else {
    @if ($hide) {
      opacity: 1;
      visibility: visible;
    }
    @content;
  }
}


/* INPUT PLACEHOLDER */
// usage example: @include input-placeholder { color: $color; }

@mixin input-placeholder {
	&.placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
	&::-webkit-input-placeholder { @content; }
}


/* RESPONSIVE RATIO */
// usage example: @include responsive-ratio(16,9);

@mixin responsive-ratio($x,$y, $pseudo: false) {
	$padding: unquote( ( $y / $x ) * 100 + '%' );
	@if $pseudo {
		&:before {
			@include pseudo($pos: relative);
			width: 100%;
			padding-top: $padding;
		}
	} @else {
		padding-top: $padding;
	}
}


/* WIDTH AND HEIGHT */
// usage example: @include size(50px, 50px);

@mixin size($width, $height) {
	width: $width;
	height: $height;
}


/* SQUARE SHAPE */
// usage example: @include square(50px);

@mixin square($size) {
	@include size($size, $size);
}


/* CIRCLE SHAPE */
// usage example: @include circle(50px);

@mixin circle($size) {
	@include square($size);
	border-radius: 50%;
}


/* TEXT TRUNCATE */
// usage example: @include text-truncate(100%);

@mixin text-truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* BUTTON STYLE */
// usage example: @include btn-style(color-bg);

@mixin btn-style($background-color) {
	@extend %btn;
	background-color: $background-color;
	&:hover {
		background-color: lighten($background-color, 10%);
	}
}


/* BUTTON VARIANT */
// usage example:
// @include btn-variatn(color, bg-color);
// Optional border-color:
// @include btn-variatn(color, bg-color, border-color);
@mixin button-variant($color, $bg-color, $border-color:"") {
	color: $color;
	background-color: $bg-color;
	@if $border-color != "" {
		border: 1px solid $border-color;
	}
	&:hover,
	&:focus {
		background-color: lighten($bg-color, 12%);
	}
}


@mixin link-arrow($color) {
	a {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: $color;
    text-align: center;
		display: inline-block;

    &::after {
    	content: '';
    	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 284.935 284.936" fill="#{$color}"><path d="M222.701 135.9L89.652 2.857C87.748.955 85.557 0 83.084 0c-2.474 0-4.664.955-6.567 2.857L62.244 17.133c-1.906 1.903-2.855 4.089-2.855 6.567 0 2.478.949 4.664 2.855 6.567l112.204 112.204L62.244 254.677c-1.906 1.903-2.855 4.093-2.855 6.564 0 2.477.949 4.667 2.855 6.57l14.274 14.271c1.903 1.905 4.093 2.854 6.567 2.854 2.473 0 4.663-.951 6.567-2.854l133.042-133.044c1.902-1.902 2.854-4.093 2.854-6.567s-.945-4.664-2.847-6.571z"/></svg>');
    	background-position: center center;
    	width: 13px;
    	height: 13px;
    	display: inline-block;
    	margin-left: 4px;
    	vertical-align: middle;
    }
	}
}