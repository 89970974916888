.control-nav-carousel {
	margin-right: 20px;
	display: flex;
	
	&__button {
		height: 100%;
		padding: 0 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		@extend %btn;

		svg {
			width: 18px;
			height: 18px;
			display: inline-block;
			position: relative;
		}
	}

	@include mq('tablet', max) {
		margin-right: 5px;
	}
}