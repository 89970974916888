/**
 * Radio styles
 */
/* Reset radio */
[type="radio"] {
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
  }

  &:checked ~ label {
    &::after {
      opacity: 1;
    }
  }
}

.radio {
  position: relative;

  > input {
    &:checked ~ .radio__wrap {
      border-color: $green;
    }

    &:checked ~ .radio__state {
      background-color: $green;

      svg {
        fill: $white;
      }
    }
  }

  &__wrap {
    text-align: center;
    display: block;
    padding: 15px 30px 18px;
    cursor: pointer;
    border-radius: 14px;
    border: 1px solid rgba($black, .1);
    transition: all 200ms ease;
    box-shadow: 4px 5px 10px 1px rgba($black, .1);
  }

  &__media {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    margin-bottom: 3px;

    svg {
      width: auto;
      height: 30px;
      fill: $red;
    }
  }

  &__title {
    font-family: $font-secondary;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    display: block;
  }

  &__state {
    position: absolute;
    width: 34px;
    height: 34px;
    top: -12px;
    right: -12px;
    border: 1px solid rgba($black, .1);
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease;

    svg {
      fill: rgba($black, .1);
      width: 15px;
      height: 15px;
      position: relative;
      top: 2px;
    }
  }

  @include mq('tablet', max) {
    &__state {
      right: -5px;
    }
  }
}

.radio-square {
  padding-bottom: 18px;

  > input {
    &:checked ~ .radio-square__wrap {
      border-color: $green;
    }

    &:checked ~ .radio-square__state {
      background-color: $green;

      svg {
        fill: $white;
      }
    }
  }

  &__wrap {
    display: block;
    text-align: center;
    padding: 20px 15px 24px;
    border: 1px solid rgba($black, .1);
    cursor: pointer;
  }

  &__title {
    font-family: $font-secondary;
    font-size: 18px;
    display: block;
    margin-bottom: .35em;
  }

  &__category {
    font-family: $font-secondary;
    font-size: 12px;
    color: $green;
    display: block;
    letter-spacing: 1px;
  }

  &__state {
    position: absolute;
    width: 34px;
    height: 34px;
    bottom: 1px;
    left: 0;
    right: 0;
    border: 1px solid rgba($black, .1);
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease;
    margin-left: auto;
    margin-right: auto;

    &::before,
    &::after {
      content: '';
      height: 100%;
      width: 18px;
      background-color: $white;
      position: absolute;
      top: 0;
    }

    &::before {
      right: calc(100% + 1px);
    }

    &::after {
      left: calc(100% + 1px);
    }

    svg {
      fill: rgba($black, .1);
      width: 15px;
      height: 15px;
      position: relative;
      top: 2px;
    }
  }
}

// [type="radio"]:checked ~ label {
//   .radio {
//     &__icon {
//       background-color: $yellow;
//       border-color: $yellow;

//       span {
//         svg {
//           fill: $white;
//         }
//       }
//     }
//   }
// }