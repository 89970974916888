.live-fb {
	background-color: $green-light;
  padding: 20px 0px 12px;
  margin-top: -20px;
  margin-bottom: 20px;

  &__content {
  	display: flex;
  	position: relative;
  }

	&__media {
		width: 230px;
		margin-right: 20px;
	}

	&__data {
		font-family: $font-secondary;
		width: 430px;
		max-width: 100%;

		&-notification {
	    font-size: 12px;
			font-weight: bold;
	    text-transform: uppercase;
	    color: $white;
			background-color: $red;
	    padding: .2em .4em;
    	letter-spacing: 0.8px;
		}

		&-title {
			font-size: 25px;
			text-transform: uppercase;
			color: lighten($black, 25%);
			margin-top: .25em;
			margin-bottom: .375em;
		}

		p {
			font-family: SFProDisplay;
			font-size: 14px;
			line-height: 1.43;
			color: lighten($black, 25%);
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		background: url('../images/icons/close.png') center/100% no-repeat;
		width: 18px;
		height: 18px;
		display: block;
		cursor: pointer;
	}

	@include mq('tablet-wide', max) {
		margin-top: 0;
	}

	@media (max-width: 600px) {
		padding: 15px 0;
		
		&__content {
			flex-wrap: wrap;
		}

		&__data {
			order: 1;
			width: 100%;

			&-title {
				font-size: 16px;
			}

			p {
				display: none;
			}
		}

		&__media {
			order: 2;
			width: 100%;
			margin-right: 0;
		}
	}
}