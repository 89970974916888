/* ===========================
	 ACCOUNT
	 =========================== */

.account {
	max-width: 900px;
	@extend %center-block;
	
	.mfp-close {
		font-family: $font-secondary;
		font-weight: 200;
		font-size: 50px;
		right: 8px;
    top: 3px;
	}
	
	&__content {
		font-family: $font-secondary;
		padding: 70px 20px 110px;
		text-align: center;
		background-color: $green-light;


		p {
			line-height: 1.45;
		}
	}

	&__option {
		display: none;
	}

	&__title {
		font-size: 24px;
		font-weight: 600;
		color: $black;
		margin-bottom: .75em;
	}

	&__access {
		display: block;
		margin-top: 40px;
		
		&-btn {
			+ .account__access-btn {
				margin-top: 20px;
			}

			a {
				background-color: $white;
				color: #073d83;
				display: inline-block;
				width: 300px;
				max-width: 100%;
				padding: 10px 22px;
				text-align: left;
				border-radius: 5px;
				box-shadow: 3px 3px 10px rgba($black, .1);

				&.google,
				&.facebook {
					&::before {
						content: '';
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100%;
						display: inline-block;
						vertical-align: middle;
						height: 18px;
						width: 18px	;
						position: relative;
						top: -2px;
						margin-right: 36px;
					}
				}

				&.google {
					&::before {
						background-image: url('../images/icons/google-account.png');
					}
				}

				&.facebook {
					&::before {
						background-image: url('../images/icons/facebook-account.png');
					}
				}
			}
		}
	}

	&__feedback {
		margin: 30px 0;

		p a {
			color: #539903;
			text-decoration: underline;
		}
	}

	&__link {
		a {
			font-size: 14px;
			color: $green;
			text-decoration: underline;
		}
	}

	/* Media queries general */
	@include mq('phone-wide', max) {
		height: 100vh;
		width: calc(100% + 20px);
		margin-left: -10px;
		background-color: $green-light;
		padding: 50px 15px 20px;

		&__content {
			height: 100%;
			padding: 0;
			overflow: auto;
		}

		&__title {
			max-width: 260px;
			margin-left: auto;
			margin-right: auto;
		}

		.mfp-close {
			top: 0;
		}
	}
}

/* Account mobile */
.account-mobile {
	background-color: $white;
	padding: 10px;
	background-color: lighten($gray, 10%);
	display: none;

	&.is-logged {
		.account-mobile__options {
			display: none;
		}

		.account-mobile__user {
			display: block;
		}
	}

	&__options {
		display: flex;
		flex-wrap: wrap;
		margin-left: -5px;
		margin-right: -5px;
	}

	&__option {
		flex: 50%;
		max-width: 50%;
		padding-left: 5px;
		padding-right: 5px;

		.btn {
			border-radius: 0;
			width: 100%;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			font-weight: 500;
		}
	}

	&__user {
		display: none;

		&-name {
			font-size: 16px;
			color: $black;
		}

		&-options {
			padding: 10px 0;

			li {
				border-bottom: 1px solid rgba($black, .5);

				a {
					font-size: 14px;
					color: $black;
					padding: .55em 0;
					display: block;
				}
			}
		}
	}

	/* Media queries general */
	@include mq('phone-wide', max) {
		&__option {
			flex: 100%;
			max-width: 100%;

			+ .account-mobile__option {
				margin-top: 10px;
			}
		}
	}
}