.search-btn {
	display: flex;
	align-items: center;

	&__icon {
		margin-right: 7px;
		line-height: 0;

		svg {
			width: 18px;
			height: auto;
		}
	}

	&__input {
		font-family: $font-secondary;
		font-weight: 500;
		font-size: 12px;
		font-style: italic;
		display: inline-block;
		padding-left: 2px;
		padding-right: 12px;
		line-height: 1.425;
		border-bottom: 1px solid $black;
	}
}

/**
 * SEARCH POPUP
 */
.search {
	max-width: 900px;
	@extend %center-block;
	
	&__form {
		display: flex;
		font-family: $font-secondary;
		width: 100%;
	}

	&__input {
		width: 100%;
		background-color: $white;
		height: 70px;
		position: relative;

		input {
			font-family: $font-secondary;
			font-size: 20px;
			padding: .5em .75em .5em 3em;
			width: 100%;
			height: 100%;
			border: 0;
			@include input-placeholder { color: $green; }
		}

		&-icon {
			line-height: 0;
			left: 18px;
			@include center-elm(false, true);

			svg {
				max-width: 28px;
				height: auto;
				fill: $green;
			}
		}
	}

	&__btn {
		width: 200px;
		flex: 0 0 auto;
		
		button {
			font-size: 20px;
			font-weight: 500;
			background-color: $green;
			width: 100%;
			height: 100%;
			@extend %btn;
		}
	}
}

/* Search mobile */
.search-mobile {
	display: none;
	position: absolute;
	width: 100%;

	.search__form {
		padding: 10px;
		background-color: $gray;
	}

	.search__input {
		height: 34px;

		input {
			font-size: 16px;
			padding: .5em .75em;
		}
	}

	.search__btn {
		width: 70px;

		button {
			font-size: 12px;
			padding: 0;
		}
	}
}