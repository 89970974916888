.featured-news-main {
	margin-top: -20px;
	
	&__content {
		position: relative;
		display: flex;
		align-items: center;
		// overflow: hidden;
	}

	&__text {
		width: 100%;
		left: 0;
		z-index: 10;
		@include center-elm(false, true);

		&-content {
			display: flex;
			color: $white;
		}
	}

	&__gallery {
		display: block;
		width: 100%;

		.item {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 482px;
		}
	}

	&__tab {
		counter-reset: number;

		&-index {
			font-family: $font-secondary;
			counter-increment: number;
			position: relative;
			border-bottom: 1px solid $white;
			padding: 9px 0;
			opacity: 0.5;
			transition: all 200ms ease;
			cursor: pointer;
			margin-right: 24px;

			&:first-child {
				padding-top: 5px;
			}

			&.is-active {
				opacity: 1;
			}

			&::after {
				content: '0'counter(number);
				font-size: 16px;
			}
		}

		&-content {
			width: 400px;
			max-width: 100%;
		}

		&-item {
			&-title {
				font-size: 30px;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: .375em;
				text-shadow: 0 0 10px rgba($black, .5);
			}

			&-category {
				font-family: $font-secondary;
				font-size: 14px;
				display: inline-block;
				text-shadow: 0 0 10px rgba($black, .5);

				&::before {
					content: '';
					width: 6px;
					height: 6px;
					background-color: $white;
					display: inline-block;
					vertical-align: middle;
					margin-right: 5px;
				}
			}

			p {
				line-height: 1.3;
				margin-bottom: .5em;
			}
		}
	}

	&__social {
		margin-top: 10px;
		overflow: hidden;
		border-radius: 40px;

		.social-share {
			position: relative;

			&.is-open {
				.social-share__list {
					transform: translateY(0) translateX(0);
				}
			}

			&__list {
				transform: translateY(0) translateX(-100%);
				top: 0;
				left: 40px;
				
				li {
					display: inline-block;
					border-radius: 50%;
				}
			}

			&__btn {
				border-radius: 50%;
			}
		}
	}
	
	/* Media queries */
	@include mq('tablet-wide', max) {
		&__gallery {
			.item {
				height: 400px;
			}
		}

		&__tab {
			&-item {
				&-title {
					font-size: 25px;
				}
			}
		}
	}

	@include mq('tablet', max) {
		&__gallery {
			.item {
				height: 250px;
			}
		}

		&__tab {
			&-content {
				display: flex;
				align-items: center;
			}

			&-item {
				&-title {
					font-size: 18px;
				}

				&-category {
					font-size: 12px;
				}

				p {
					display: none;
				}
			}

			&-index {
				padding: 4px 0;

				&::after {
					font-size: 14px;
				}
			}
		}
	}
}