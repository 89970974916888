@font-face {
  font-family: 'RobotoSlab';
  src: url('../fonts/RobotoSlab-Regular.woff2') format('woff2'),
    url('../fonts/RobotoSlab-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'RobotoSlab';
	src: url('../fonts/RobotoSlab-Bold.woff2') format('woff2'),
		url('../fonts/RobotoSlab-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/SanFranciscoDisplay-Regular.woff2') format('woff2'),
      url('../fonts/SanFranciscoDisplay-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/SanFranciscoDisplay-Medium.woff2') format('woff2'),
      url('../fonts/SanFranciscoDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/SanFranciscoDisplay-Semibold.woff2') format('woff2'),
      url('../fonts/SanFranciscoDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/SanFranciscoDisplay-Bold.woff2') format('woff2'),
      url('../fonts/SanFranciscoDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}