.form {
	&__wrap {
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
		margin-right: -10px;
		margin-bottom: 10px;

		&.coupon {
			flex-wrap: wrap;
			
			label {
				font-family: $font-secondary;
				font-size: 16px;
				width: 100%;
				padding-left: 10px;
				padding-right: 10px;
				padding-top: 10px;
				padding-bottom: 10px;
				flex-basis: 1;
				flex-grow: 1;
				position: relative;

				&::before {
					content: '';
					height: 1px;
					width: calc(100% - 10px);
					position: absolute;
					top: 0;
					left: 0;
					background-color: #979797;
					display: block;
				}
			}
		}
	}

	&__label {
		font-family: $font-secondary;
		font-weight: 200;
		font-size: 17px;
		margin: .4em 0 .65em;
		display: inline-block;
	}

	&__input {
		padding-left: 10px;
		padding-right: 10px;
		flex-basis: 1;
		flex-grow: 1;

		&.column {
			flex: 50%;
			max-width: 50%;
		}

		input {
			background-color: #EDEBEB;
			width: 100%;
			color: $black;
			border: 0;
			border-radius: 5px;
			height: 40px;
			line-height: 40px;
			padding: 6px 15px;
			font-size: 15px;
			@include input-placeholder { color: $black; }
		}
	}

	&__action {
		margin-top: 15px;

		button {
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.5px;
			width: 100%;
			display: block;
			background-color: $green;
			color: $white;
			text-transform: uppercase;
			height: 40px;
			padding: 4px 12px;
			border-radius: 5px;
			@extend %btn;
		}
	}

	.field-wrapper {
		margin-bottom: 20px;

		label {
			font-family: $font-secondary;
			font-weight: 200;
			font-size: 16px;

			+ input,
			+ textarea,
			+ select,
			+ .select-custom {
				margin-top: 8px;
			}
		}

		input,
		select {
			font-family: $font-secondary;
			font-weight: 200;
			font-size: 14px;
			background-color: #EDF4EE;
			border-radius: 4px;
			padding: .55em .75em;
			border: 0;
			width: 100%;
			height: 40px;
			color: $black;
			@include input-placeholder { color: $black; }
		}

		select {
			background-color: $transparent;
		}

		@include mq('phablet', max) {
			margin-bottom: 15px;

			[class^="col-"] + [class^="col-"] {
				margin-top: 15px;
			}
		}
	}

	.field-action {
		margin-top: 30px;

		.btn {
			font-size: 16px;
			font-weight: bold;
			text-transform: uppercase;
			padding: .55em 2.25em;
		}
	}

	/* Media queries general */
	@include mq('tablet', max) {
		&__input {
			&.column {
				flex: 100%;
				max-width: 100%;
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}


.switch {
	display: flex;
	align-items: center;

	&__element {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 14px;

		input[type="checkbox"] {
			display: none;
	
			&:checked + .switch__slider {
				background-color: rgba(#009688, .5);
	
				&::before {
					transform: translateX(22px);
					background-color: #009688;
				}
			}
		}
	}

	&__slider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 8px;
		cursor: pointer;
		background-color: rgba(#B9B9B9, .5);
		transition: all 400ms ease;

		&::before {
			content: '';
			position: absolute;
			left: -3px;
			bottom: -4px;
			width: 22px;
			height: 22px;
			border-radius: 50%;
			background-color: #383938;
			transition: all 400ms ease;
		}
	}

	&__result {
		margin-left: 15px;
		font-size: 14px;
	}
}