
.humor {
	&__item {
		margin-bottom: 20px;
	}

	&__action {
		text-align: left;
	}

	@media (max-width: 575px) {
		&__item{
			margin-bottom: 15px;
		}
	}
}

.jokes {
	&__item {
		position: relative;
		margin: 0 0 20px;
		overflow: hidden;

		&--with-image {
			.news-card__subtitle {
				color: $green;
				padding-right: 35px;
				
				&::before {
					background-color: $green;
				}
			}

			.jokes__item-title {
				padding-right: 35px;
			}
		}

		&-content {
			border-bottom: 1px solid $gray;
			padding-bottom: 12px;
		}

		&-title {
			font-size: 20px;
			margin: 0 0 5px;
		}

		&-date {	
			font-size: 10px;
			color: $gray-dark;
			font-weight: normal;
			font-size: 12px;
			margin: 20px 0 0;
		}

		p {
			font-size: 16px;
			letter-spacing: 0.4px;
			line-height: 24px;
			color: $gray-dark;
		}
	}

	&__action {
		text-align: left;
	}
	
	@include mq('tablet-wide', max) {
		&__item {
			&-title {
				font-size: 18px;
			}

			&--with-image {
				.jokes__item__wrapper {
					figure {
						width: 250px;
						flex: 0 0 auto;
					}
				}

				.jokes__item-content {
					width: 100%;

					p {
						display: none;
					}
				}
			}
		}
	}

	@include mq('tablet', max) {
		&__item {
			&--with-image {
				.news-card__share {
					display: none;
				}

				.jokes__item__wrapper {
					figure {
						width: 170px;
					}
				}

				.news-card__subtitle {
					padding-right: 0;
				}

				.jokes__item-title {
					font-size: 14px;
					padding-right: 0;
				}

				.jokes__item-content {
					width: 100%;
					padding-bottom: 0;
				}
			}
		}
	}

	@include mq('phone-wide', max) {
		&__item {
			&--with-image {
				.news-card__subtitle {
					margin-top: 0;
				}

				.jokes__item-title {
					line-height: 1.1;
				}

				.jokes__item__wrapper {
					border-bottom: 0;
					
					figure {
						width: 170px;
						margin-right: 10px;
						margin-bottom: 0;
						flex: 0 0 auto;
					}
				}
			}
		}
	}

	@include mq('phone-wide', max) {
		&__item {
			&--with-image {
				.jokes__item__wrapper {
					
					figure {
						width: 140px;
					}
				}
			}
		}
	}
}