/* =================
	 SIDEBAR
	 ================= */
.sidebar-main {
	position: relative;
	width: 285px;
	order: 2;

	&__item {
		max-width: 265px;

		+ .sidebar-main__item {
			margin-top: 20px;
		}
	}

	+ .section-main {
		width: calc(100% - 285px);
	}

	@include mq('tablet', max) {
		display: none;

		&.is-home {
			display: block;
			width: 100%;
			margin-bottom: 20px;

			.sidebar-main {
				&__item {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}
}