/* ================
   WEATHER
   ================ */

.weather {
	/* Forecast */
	&-forecast {
		&__site {
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: underline;
		}

		&__content {
			display: flex;
			align-items: center;
			padding: 15px 0;
		}

		&__icon {
			// width: 69px !important;
			// flex: 0 0 auto;
			margin-right: 10px;
			display: flex;

			svg {
				fill: $blue-light2;
				width: 56px;
				height: 56px;
			}
		}

		&__data {
			font-family: $font-secondary;
			
			> span {
				font-size: 12px;
				display: block;
			}
		}
	}
	
	/* Gallery */
	&-gallery {
		margin-bottom: 20px;

		&-title {
			font-family: $font-secondary;
			color: $white;
			font-size: 15px;
			text-align: center;
			display: block;
			font-weight: 600;
		}
		
		.owl-carousel {
			width: calc(100% - 140px);
			@extend %center-block;

			.owl-nav {
				.owl-prev,
				.owl-next {
					width: 30px;
					height: 30px;
					background-color: $blue-light2;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						width: 18px;
						height: 18px;
						fill: $white;
					}
				}

				.owl-prev {
					right: calc(100% + 40px);
				}

				.owl-next {
					left: calc(100% + 40px);
				}
			}
		}

		&-item {
			color: $blue-light2;
		}
	}

	/* Report */
	&-report {
		height: auto;

		&__status {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 3px;
			display: inline-block;
			vertical-align: baseline;

			&.normal {
				background-color: $green;
			}

			&.alert {
				background-color: $yellow;
			}

			&.emergency {
				background-color: $red-dark;
			}
		}

		&__legend {
			padding: 5px 8px;
			border-bottom: 1px solid lighten($black, 60%);
			font-size: 12px;

			&-item {
				display: inline-block;
				font-size: 10px;

				+ p {
					margin-left: 15px;
				}
			}
		}

		.external-data {
			&__tab {
				&-carousel {
					display: flex;
					border-bottom: 1px solid lighten($black, 60%);
				}
				
				&-item {
					font-size: 12px;
					flex: 1 0 auto;
					color: lighten($black, 25%);
					justify-content: center;
					border-left: 0 !important;
					border-right: 0 !important;

					&:after {
						width: 0;
						height: 0;
					}
				}
			}
		}
	}


	/* Media queries */
	@include mq('tablet-wide', max) {
		&-gallery {
			.owl-carousel {
				width: calc(100% - 100px);

				.owl-nav {
					.owl-prev {
						right: calc(100% + 20px);
					}

					.owl-next {
						left: calc(100% + 20px);
					}
				}
			}
		}
	}

	@include mq('tablet', max) {
		&-content {
			background-color: $blue-light2;
			width: calc(100% + 30px);
			margin-left: -15px;
			margin-top: -15px;
			margin-bottom: 15px;
			padding: 15px 15px;
		}

		&-filter {
			p {
				font-family: $font-secondary;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 1em;
				color: $white;
			}
		}

		&-gallery {
			margin-bottom: 0;
			padding-top: 20px;
		}

		&-forecast {
			color: $white;

			&__content {
				max-width: 120px;
				margin-left: auto;
				margin-right: auto;
			}

			&__icon {
				svg {
					fill: $white;
				}
			}
		}
	}
}