// Transition elements
$global_transition: all 300ms ease-in-out;

// Font
$font-main: 'RobotoSlab';
$font-secondary: 'SFProDisplay';

.margin-bottom-30 {
	margin-bottom: 30px;
}
