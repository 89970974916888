.language-mobile {
	background-color: $gray;
	height: auto;
	height: initial;

	&::before,
	&::after {
		height: 0;
	}

	button {
		font-family: $font-main;
		font-size: 12px;
    font-weight: bold;
		text-align: left;
		text-transform: uppercase;
    color: #2D2D2D;
    height: 33px;
    width: 100%;
    padding: 0 1.1em;
    background: url('../images/icons/arrow-select-black.png') right 12px center/16px 10px no-repeat;
    @extend %btn;
	}

	.dropdown-menu {
		position: initial;

		li {
			text-align: left;
			padding: 8px 14px 8px 25px;
			font-size: 13px;
		}
	}
}