.sidebar-ad {
	~ .sidebar-ad {
		margin-top: 20px;
	}
}

.adMobileFull {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($white, .65);
	transition: all 500ms ease;
	transform: translateX(0);
	z-index: 9999;

	&.is-removing {
		transform: translateX(-100%);
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		width: 55px;
		height: 55px;
		z-index: 10;
		background-color: $transparent !important;
		@extend %btn;

		svg {
			width: 35px;
			height: 35px;
		}
	}

	&__wrap {
		position: absolute;
		width: 85%;
		display: block;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		bottom: 0;
		box-shadow: 0 -2px 15px 1px rgba($black, .25);
	}

	&__link {
		display: block;
		
		img {
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include mq('phone-wide') {
		display: none;
	}
}