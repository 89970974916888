.gallery-icons {
	padding: 10px 12px;
	margin-bottom: 12px;
	margin-top: -20px;
	position: relative;

	&--orange {
		background-color: #e0690e;
	}

	&__item {
		img {
			height: 25px;
			width: auto !important;
		}
	}

	&__content {
		width: calc(100% - 77px);

		.owl-carousel {
			.owl-nav {
				position: absolute;
				top: -10px;
				right: calc(-77px);
    		width: 65px;
				height: calc(100% + 20px);
				display: flex;
				flex-wrap: wrap;

				.owl-prev,
				.owl-next {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50%;

					&.disabled {
						display: none;
					}

					svg {
						width: 18px;
						height: 18px;
						fill: $white;
					}
				}
			}
		}
	}

	/* Media queries */
	@include mq('tablet', max) {
		margin-top: -14px;
		padding: 10px 0;

		&__content {
			width: calc(100% - 60px);
			margin-left: auto;
			margin-right: auto;

			.owl-carousel {
				.owl-nav {
					position: initial;

					.owl-prev,
					.owl-next {
						display: flex;
						align-items: center;
						justify-content: center;
						width: initial;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						width: 25px;
						height: 25px;
					}

					.owl-prev {
						right: calc(100% + 5px);
					}

					.owl-next {
						left: calc(100% + 5px);
					}
				}
			}
		}
	}
}