.accordion {
  .panel {
    &__header {
      .action {
        svg {
          width: 10px;
          height: 10px;
          fill: $green;
        }
      }
    }

    &__content {
      display: none;
    }
  }
}

.wp-faq {
  .accordion {
    padding: 8px 10px;
    background-color: rgba($gray, .1);
  }

  .panel {
    &:last-of-type {
      .panel__header {
        border-bottom-width: 1px;
      }
    }

    &__header {
      font-family: $font-secondary;
      font-size: 17px;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: rgba($gray, .5);
      min-height: 80px;
      padding: 20px 27px;
      display: block;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: darken($blue-dark, 20%);

      .action {
        margin-left: 10px;
      }
    }

    &__content {
      font-family: $font-secondary;
      padding: 20px;
      background-color: $white;
      border: 1px solid rgba($gray, .5);
      margin-bottom: 10px;
    }
  }

  @include mq('tablet', max) {
    .panel {
      &__header {
        font-size: 14px;
        min-height: 40px;
        padding: 15px 15px;
      }

      &__content {
        padding: 15px;

        p,
        li,
        span {
          font-size: 14px;
        }
      }
    }
  }
}

