.btn-simple {
	border: solid 1px $green;
	background: $white;
	font-size: 16px;
	color: $green;
	font-family: SFProDisplay;
	font-weight: bold;
	letter-spacing: 0.3px;
	text-align: left;
	padding: 0.5em 2em;
	text-transform: uppercase;
}

.btn {
	font-family: SFProDisplay;
	font-weight: 200;
	border-radius: 5px;
	font-size: 12px;
	padding: .5em .7em;
	@extend %btn;
	
	&--green {
		@include button-variant($white, $green);
	}

	&--red {
		@include button-variant($white, $red);
	}
}