.external-data {
	&__header {
		border-color: lighten($black, 60%) !important;

		h3 {
			color: $green !important;
			background-color: $white !important;
			min-width: auto !important;

			&::after {
				border-color: $white !important;
			}
		}

		.control-nav-carousel {
			margin-right: 10px;
			line-height: 0;

			&__button {
				width: 18px;
				height: 18px;
				padding: 0;
			}
		}
	}

	&__content {
		border: 1px solid lighten($black, 60%);
		border-top: 0;
		margin-top: -20px;
	}

	&__tab {
		border-bottom: 1px solid lighten($black, 60%);
		&-carousel {
			.owl-stage {
				border-bottom: 1px solid lighten($black, 60%);
			}

			.owl-item {
				&:first-of-type {
					.item {
						border-left: 0 !important;
						
					}
				}
			}
		}

		&-item {
			font-size: 14px;
			color: $green;
			height: 35px;
			padding: 0 6px;
			display: flex;
			align-items: center;
			cursor: pointer;
			border-left: 1px solid $transparent;
			border-right: 1px solid $transparent;
			position: relative;
			transition: all 100ms ease;

			&:hover {
				background-color: lighten($gray, 10%);
			}

			&.is-active {
				border-left: 1px solid lighten($black, 60%);
				border-right: 1px solid lighten($black, 60%);
				background-color: $transparent;
				font-weight: bold;

				&::after {
					content: '';
					height: 1px;
					width: 100%;
					position: absolute;
					left: 0;
					bottom: -1px;
					display: block;
					background-color: $white;
				}
			}
		}
	}

	&__cta {
		padding: 4px 0 6px;
		text-align: center;
		@include link-arrow($green);
	}

	&.is-weather {
		.external-data {
			&__header {
				h3 {
					color: $green !important;
				}
			}

			&__table {
				thead {
					tr > th {
						font-size: 10px;
						text-align: center;
						color: $green;
						padding: 9px 3px;
						background-color: $white;
						vertical-align: middle;
						border-right: 1px solid lighten($black, 60%);

						&[rowspan] {
							&:last-of-type {
								border-right: 0;
							}
						}
					}
				}
				tbody {
					tr {
						&.rowspan {
							> td {
								font-size: 8px;
							}
						}
	
						&:nth-of-type(odd) {
							background-color: lighten($gray, 10%);
						}
	
						&:nth-of-type(even) {
							background-color: $white;
						}
					}
	
					tr > th,
					tr > td {
						font-size: 10px;
						text-align: center;
						vertical-align: top;
	
						&[rowspan] {
							font-size: 10px;
						}
					}
	
					tr > td {
						&:last-of-type {
							width: 50px;
							font-size: 10px;
	
							> span {
								float: right;
								position: relative;
								top: 2px;
							}
						}
					}
				}
			}
		}
	}

	&__table {
		width: 100%;
		tr {
			&.rowspan {
				> td {
					background-color: $white;
				}
			}

			&:nth-of-type(odd) {
				background-color: $white;
			}

			&:nth-of-type(even) {
				background-color: lighten($gray, 10%);
			}
		}

		tr > th,
		tr > td {
			font-size: 12px;
			text-align: center;
			padding: 9px 4px;
			border-bottom: 1px solid lighten($black, 60%);

			&[rowspan] {
				border-right: 1px solid lighten($black, 60%);
			}
		}

		tr > th {
			font-weight: bold;
			vertical-align: top;

			&:nth-of-type(1),
			&:nth-of-type(2) {
				width: 30%;
			}

			&:nth-of-type(3) {
				width: 40%;
			}

			span {
				font-size: 8px;
				font-weight: normal;
				display: block;
			}
		}

		tr {
			&:last-of-type {
				> td {
					border-bottom: 0;
				}
			}
		}
	}
}