h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
div,
main,
header,
nav,
section,
article,
aside,
footer,
figure {
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	border: 0;
}