body {
	&.inner-page {
		.main {
			padding-top: 120px;
		}

		.header__site-nav-list {
			position: initial;
		}
	}

	@include mq('tablet-wide', max) {
		&.inner-page {
			.main {
				padding-top: 45px;
			}
		}
	}
}

.wrapper-wp {
	.wp-edit {
		margin-top: 20px;

		&__head {
			display: flex;
			align-items: center;
			margin-bottom: 30px;

			&-icon {
				width: 58px;
				height: 58px;
				border-radius: 50%;
				box-shadow: 1px 1px 2px 1px rgba($black, .2);
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 15px;

				svg {
					width: 30px;
					height: 30px;
					fill: $red;
				}
			}

			&-text {
				font-size: 22px;
				font-weight: bold;
			}
		}

		&-item {
			border: 1px solid rgba($light-gray, .35);
			border-radius: 8px;
			padding: 15px 25px 35px;

			h4 {
				margin-bottom: 25px;
			}
		}

		@include mq('tablet', max) {
			&__head {
				&-icon {
					width: 40px;
					height: 40px;

					svg {
						width: 22px;
						height: 22px;
					}
				}

				&-text {
					font-size: 18px;
				}
			}
		}
	}

	.wp-title {
		font-size: 22px;
		font-weight: normal;
		line-height: 1.175;
		color: $green;
		margin-bottom: .9em;
	}
	
	.wp-text {
		font-family: $font-secondary;
		font-size: 16px;
		margin-bottom: 1.75em;
	}

	.wp-empty {
		.wp-title {
			color: $gray-dark;
		}

		&__action {
			margin-top: 30px;
			
			.btn {
				font-size: 16px;
				font-weight: bold;
				text-transform: uppercase;
				padding: .75em 1.25em;
			}
		}
	}

	p {
		font-family: $font-secondary;
	}

	.tab {
		&__items {
			font-family: $font-secondary;
			font-size: 16px;
			font-weight: bold;
			display: flex;
			align-items: center;
			color: $gray-dark;
			position: relative;

			&::after {
				content: '';
				height: 1px;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				background-color: rgba($gray-dark, .4);
			}

			li {
				padding: 9px 4px;
				margin: 0 10px;
				cursor: pointer;
				position: relative;

				&.is-active {
					pointer-events: none;

					&::after {
						content: '';
						height: 5px;
						width: 100%;
						position: absolute;
						left: 0;
						bottom: 0;
						display: block;
						background-color: $red;
						z-index: 10;
					}
				}
			}
		}

		&__content {
			&-item {
				padding: 30px 0;

				.message-box {
					&:first-of-type {
						margin-top: -10px;
					}

					&:last-of-type {
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	// Message
	.message-box {
		margin: 15px 0;
		background-color: #ccc;
		padding: 13px 20px;
		
		&.is-warning {
			background-color: #EB9507;
		}

		&.is-error {
			background-color: $red-dark;
		}

		p {
			font-size: 16px;
			line-height: 1.15;
			color: $white;			
		}
	}

	.editions-secondary {
		border-top: 0;
		margin-top: 0;
    padding-top: 0;
	}

	@include mq('tablet', max) {
		.wp-title {
			font-size: 16px;
		}
	}
}

.suscr-data {
	margin: 15px 0 30px;

	&__title {
		font-family: $font-secondary;
		font-size: 15px;
		letter-spacing: 0.5px;
		display: block;
		color: #4C4B47;
		margin-bottom: .5em;
	}

	&__text {
		font-size: 22px;
		font-weight: bold;
		color: #4C4B47;
	}
}

.main {
	padding-top: 20px;
	transition: all 200ms ease;

	&.news {
		transition: initial;

		.is-sticky & {
			padding-top: 128px;
			transition: initial;

			@include mq('tablet-wide', max) {
				padding-top: 170px;
			}
		}
	}

	@include mq('tablet-wide', max) {
		padding-top: 45px;
	}
}

.placeholder {
	+ .main {
		display: none;
		opacity: 0;
	}
}

.section-main {
	order: 1;

	&.followus {
		margin-bottom: 20px;

		@include mq('tablet', max) {
			display: none;
			margin-bottom: 15px;
		}
	}

	&.agro-queen-shortcut,
	&.founder-shortcut,
	&.print-edition-shortcut {
		margin-bottom: 20px;
	}
}

.news-section {
	margin-bottom: 20px;

	&__cta {
		display: none;
	}

	@include mq('tablet', max) {
		margin-bottom: 10px;

		&__cta {
			width: 100%;
			display: block;
			padding-left: 15px;
			padding-right: 15px;
			margin-top: 5px;
			margin-bottom: 15px;

			.btn {
				font-size: 15px;
				font-weight: 500;
				width: 100%;
				display: block;
				border-radius: 0;
				padding: .6em .7em;
				display: flex;
				align-items: center;
				justify-content: center;

				&::after {
					content: '';
					background: url('../images/icons/arrow-right-white.png') center center/100% no-repeat;
					width: 13px;
					height: 13px;
					margin-left: 7px;
				}
			}

			&--purple {
				.btn {
					@include button-variant($white, $purple);
				}
			}
		}
	}
}

.headline {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	display: inline-block;
	overflow: hidden;
	text-indent: -9999px;
}

.link {
	color: $black;
	display: inline-block;
}

/* Add main top */
.ad-main {
	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}

	@include mq('tablet-wide', max) {
		display: none;
	}
}

.ad-fullwidth-animate {
	height: 227px;
	margin: 0 0 20px;

	> div {
		height: 100%;
		
		@include mq('tablet-wide') {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__single {
		width: 33.333%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		transition: all 350ms ease 100ms;
		display: block;

		&:hover {
			width: 100%;
		}
	}

	@include mq('tablet-wide', max) {
		height: initial;

		&__single {
			width: 100%;
			height: 227px;
		}
	}

	@include mq('tablet', max) {
		&__single {
			height: 160px;
		}
	}

	@include mq('phone-wide', max) {
		&__single {
			height: 95px;
		}
	}
}

/* Video container */
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;

	video,
	iframe,
	object,
	.video-js {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	} 
}

/* Custom select */
.select-custom {
	padding: 0;
	margin: 0 auto;
	width: 100%;
	border-radius: 3px;
	overflow: hidden;
	background-color: $white;
	background: $white url('../images/icons/arrow-select.png') right 10px center/15px 8px no-repeat;

	select {
		font-family: $font-secondary;
		font-weight: bold;
		font-size: 15px;
		color: $blue-light2;
		padding: 8px 12px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    appearance: none;
	}
}


/* Feeding page styles */
.feeding {
	&--featured {
		.owl-carousel {
			display: flex;
			width: initial;
		}

		.news-card {
			&__box {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				height: 350px;
			}
		}
	}

	@include mq('tablet', max) {
		&--featured {
			.owl-carousel {
				display: block;
				width: 100%;
				margin-left: initial;
				margin-right: initial;
			}

			.news-card {
				padding-left: 0;
				padding-right: 0;

				&__box {
					height: 340px;
				}
			}
		}
	}		
}

/* Tendencia styles */
.tendencia {
	.jokes__item {
		p {
			font-family: $font-secondary;
			line-height: 1.2;
			margin: 5px 0 0;
		}

		&__wrapper {
			figure {
				max-width: 175px;
				margin-right: 10px;
			}
		}

		&-title {
			margin: 0;
			padding: 3px 0 0 0 !important;
		}

		.news-card__subtitle {
			margin-top: 0;
		}
	}
}

.list-data {
	margin-top: 12px;
	
	li {
		margin-bottom: 10px;
		display: flex;
		align-items: center;

		span {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 18px;
			height: 18px;
			margin-right: 6px;

			svg {
				width: 18px;
				height: 18px;
				fill: $green;
			}
		}
	}
}

.margtop-30 {
	margin-top: 30px;
}


/* Events */
.event-banner {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	margin-top: -15px;
	margin-bottom: 20px;
	width: 100%;
	height: 270px;
	
	@include mq('tablet-wide', max) {
		height: 220px;
	}

	@include mq('tablet', max) {
		height: 120px;
	}
}


/* Simple social inner page */
.social-simple--inner {
	@include mq('tablet', max) {
		.social-simple__item {
			+ li {
				margin-left: 5px;
			}

			a {
				width: 35px;
				height: 35px;
				border-radius: 50%;

				svg {
					fill: $white !important;
				}

				&.facebook {
					background-color: #3c5896;
				}

				&.twitter {
					background-color: #009acf;
				}

				&.email {
					background-color: $yellow;
				}

				&.link {
					background-color: $black;

					svg {
						stroke: $white;
					}
				}
			}
		}
	}
}

/* Select custom white */
.select-custom {
	&.white-style {
		border: 1px solid lighten($gray, 5%);
		box-shadow: 2px 2px 8px rgba($black, .1);
		border-radius: 3px;
		background: $white url("../images/icons/arrow-select-black.png") right 10px center/16px 10px no-repeat;

		select {
			font-size: 16px;
			font-weight: normal;
			padding: 11px 12px;
			color: $black;
		}
	}

	&.gray-style {
		// border: 1px solid lighten($gray, 5%);
		border-radius: 5px;
		background: #EDF4EE url("../images/icons/arrow-select-green.png") right 10px center/16px 10px no-repeat;

		select {
			font-family: $font-secondary;
			font-size: 16px;
			font-weight: 500;
			padding: 11px 12px;
			color: $black;
		}
	}
}

.account-nav {
	margin-top: 15px;
	margin-bottom: 25px;

	ul {
		display: flex;

		li {
			+ li {
				margin-left: 18px;
			}
		}

		a {
			color: $black;
			padding: 6px 0;
			display: block;
			border-bottom: 2px solid $transparent;

			&.is-current {
				color: $green;
				border-color: $green;
			}
		}
	}

	@include mq('tablet', max) {
		display: none;
	}

	&-current {
		margin: 20px 0 15px;
		text-align: center;

		@include mq('tablet') {
			display: none;
		}

		h1 {
			font-size: 20px;
			font-family: $font-secondary;
		}
	}
}