.editions {
	.especial {
		margin-bottom: 0;
	}

	&__filter {
		display: flex;
		margin-left: -10px;
		margin-right: -10px;
		align-items: center;

		&-text {
			font-family: $font-secondary;
			padding: 0 9px;
			font-size: 20px;
		}

		&-col {
			width: 205px;
			padding-left: 10px;
			padding-right: 10px;

			button {
				font-family: $font-secondary;
				font-weight: bold;
				font-size: 16px;
				background-color: $red-dark;
				color: $white;
				border-radius: 3px;
				width: 100%;
				padding: 10.5px 10px;
				box-shadow: 2px 2px 8px rgba($black, .1);
				@extend %btn;
			}
		}
		.select-custom {
			border: 1px solid lighten($gray, 5%);
			box-shadow: 2px 2px 8px rgba($black, .1);
			border-radius: 3px;
			background: $white url("../images/icons/arrow-select-black.png") right 10px center/16px 10px no-repeat;

			select {
				font-weight: normal;
				font-size: 16px;
				padding: 11px 12px;
				color: $black;
			}
		}

		@include mq('tablet', max) {
			flex-wrap: wrap;

			&-col {
				width: 31.666%;

				&:last-of-type {
					width: 100%;
					text-align: center;

					button {
						width: 360px;
						max-width: 100%;
						margin-top: 15px;
					}
				}
			}

			&-text {
				width: 5%;
				text-align: center;
			}
		}

		@include mq('tablet-small', max) {
			&-col {
				width: 100%;
				margin-top: 12px;

			}

			&-text {
				width: 100%;
				margin-top: 12px;
				font-size: 16px;
			}
		}
	}

	&__subtitle {
		font-family: RobotoSlab;
	  font-size: 14px;
	  font-weight: bold;
	  font-style: normal;
	  font-stretch: normal;
	  line-height: 1;
	  letter-spacing: 0.3px;
	  text-align: left;
	  color: $black;
	  padding-left: 10px;
	  margin-top: 1.4em;
	  margin-bottom: 1.4em;

	  @include mq('tablet-small', max) {
			margin-top: 1.2em;
			margin-bottom: .4em;
		}
	}

	&__item {
		background-color: rgba($green, 0.05);
		margin-top: 20px;
		margin: 20px 0 20px;
		padding: 0;

	  &-img {
	  	padding: 25px;
	  	margin: 0 0;
	  }

	  &-text {
	  	padding: 25px;

	  	&-sub {
			  font-family: RobotoSlab;
			  font-size: 30px;
			  font-weight: bold;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.23;
			  letter-spacing: 0.8px;
			  text-align: left;
			  color: $black;
			  margin: 0;
			  padding-right: 25px;
	  	}

			&-paragraph{
				font-family: RobotoSlab;
			  font-size: 16px;
			  font-weight: normal;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.5;
			  letter-spacing: 0.6px;
			  text-align: left;
			  color: #000000;

			  ul{
					padding: 10px 0 25px;
			  }
				li{
					margin-top: 15px;
			  	&::before {
						content: '';
						width: 3px;
						height: 3px;
						background-color: $black;
						display: inline-block;
						margin-right: 15px;
						position: relative;
						top: -1px;
					}
				}
			}

			&-action {
				a {
					font-family: $font-secondary;
					font-weight: bold;
					font-size: 16px;
					background-color: $red-dark;
					color: $white;
					border-radius: 3px;
					min-width: 175px;
					padding: 10.5px 10px;
					box-shadow: 2px 2px 8px rgba($black, .1);
					@extend %btn;
				}
			}
		}
	}

	&-secondary {
		padding: 15px 0 30px;
    border-top: 1px solid #dbdbdb;
    margin-top: 35px;

		&__title {
		  text-align: left;
		  color: $black;

		  h3 {
		  	font-size: 20px;
		  	letter-spacing: 0.9px;
		  	margin-bottom: .7em;
		  }
		}

		&__gallery {
			.owl-carousel {
				.owl-stage {
					display: flex;
				}
				
				.owl-nav {
					.owl-prev,
					.owl-next {
						position: absolute;
						top: 55px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $green;
						width: 24px;
						height: 85px;

						svg {
							fill: $white;
							width: 20px;
							height: 20px;
						}
					}

					.owl-prev {
						left: 0;
						box-shadow: 2px 2px 5px rgba($black, .4);
					}

					.owl-next {
						right: 0;
						box-shadow: -2px 2px 5px rgba($black, .4);
					}
				}
			}
		}

		&__item{
			&-text{
				margin-top: 10px;
				&-number{
					font-family: RobotoSlab;
				  font-size: 16px;
				  font-weight: normal;
				  font-style: normal;
				  font-stretch: normal;
				  line-height: 1;
				  letter-spacing: 0.3px;
				  text-align: left;
				  color: $black;
				  margin-bottom: 5px;
				}
				&-month{
					font-family: RobotoSlab;
				  font-size: 16px;
				  font-weight: bold;
				  font-style: normal;
				  font-stretch: normal;
				  line-height: 1.25;
				  letter-spacing: 0.3px;
				  text-align: left;
				  color: $black;
				}
			}

			&-cta {
				margin-top: 10px;
				margin-bottom: 22px;

				.btn {
					display: block;
					border-radius: 3px;
					padding: .55em .7em;
				}
			}
		}
	}

	/* Media queries */
	@include mq('tablet-wide', max) {
		&__item {
			&-img {
				padding: 20px;
			}

			&-text {
				padding: 20px;

				&-sub {
					font-size: 25px;
				}
			}
		}
	}

	@include mq('tablet', max) {
		&__item {
			&-img {
				padding: 15px;
			}

			&-text {
				padding: 15px 15px 15px 5px;

				&-paragraph {
					ul {
						padding: 5px 0;
					}

					li {
						font-size: 15px;
						margin-top: 10px;
					}
				}

				&-sub {
					font-size: 20px;
				}
			}
		}

		&-secondary {
			margin-top: 0;
		}
	}

	@include mq('tablet-small', max) {
		&__item {
			background-color: $transparent;
			
			&-img,
			&-text {
				width: 100%;
				flex: 0 0 100%;
				max-width: initial;
				padding: 0;
			}

			&-img {
				margin-bottom: 20px;

				img {
					width: 350px;
					max-width: 100%;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	@include mq('tablet-small', max) {
		&-secondary {
			&__item {
				margin-bottom: 10px;
			}
		}
	}
}


// page noticias Agroexportación 

.news-section__agroexportacion {

	.title {
		margin-bottom: 0;
	}

	.title-fruit{
		background-color: #e87e0c;
	  display: flex;
	  justify-content: space-between;
	  padding: 10px 15px;
	  color: $white;
	  margin-bottom: 20px;

	  .control-nav-carousel {
	  	margin-right: 0;

	  	button {
	  		background-color: transparent;

	  		svg {
	  			fill: $white;
	  		}
	  	}
	  }
	}


}

