html,
body {
	font-family: 'RobotoSlab', sans-serif;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	// letter-spacing: 0.5px;
	line-height: 1.25;
	@extend %font-smoothing;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;

	> a {
		color: inherit;
	}
}

p {
	+ p {
		margin-top: .85em;
	}
}
	
a {
	text-decoration: none;
}

a,
button {
	cursor: pointer;
}
	
img {
	max-width: 100%;
	display: block;
	height: auto;
}
	
ul {
	list-style-type: none;
}
	
button,
input,
select,
a {
	outline: 0;
	box-shadow: none;
	&:focus {
		outline: 0;
		box-shadow: none;
	}
}
		
input {
	border-radius: 0;
}

textarea {
	height: auto !important;
	resize: none;
}
	
