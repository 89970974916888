/* =============
	 CARDS
 	 ============= */
.event-card {
	margin-bottom: 30px;

	&__gallery {
		.item {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			height: 298px;
		}

		.owl-carousel {
			.owl-dots {
				position: absolute;
				bottom: 80px;
				text-align: center;
				width: 100%;

				.owl-dot {
					&.active {
						span {
							background-color: $violet !important;
						}
					}
					span {
						width: 16px;
						height: 16px;
						background-color: $white;
						margin: 5px 4px;
						box-shadow: 0 1px 5px rgba($black, .3);
					}
				}
			}
		}
	}

	&__info {
		width: 900px;
		max-width: 100%;
		background-color: $white;
		box-shadow: 0 0 15px 0 rgba($black, .2);
		padding: 15px;
		display: flex;
		z-index: 10;
		position: relative;
		margin-top: -74px;
		@extend %center-block;

		&-item {
			display: flex;
		}

		&-text {
			padding-right: 70px;

			p {
				letter-spacing: .5px;
				line-height: 1.35;
			}
		}

		&-title {
			font-size: 22px;
			font-weight: bold;
			letter-spacing: 0.25px;
			text-transform: uppercase;
			color: $violet;
		}

		&-date {
			margin: 4px 0;
			display: inline-block;
			color: $violet;
		}

		&-action {
			width: 120px;
			flex: 1 0 auto;
			text-align: center;
		}

		&-social {
			display: inline-block;
			margin-bottom: 40px;
		}

		&-cta {
			text-align: center;

			a {
				font-size: 12px;
				color: $green;
				border-radius: 5px;
				padding: .35em 1em;
				border: 1px solid $green;
				display: inline-block;
				transition: all 200ms ease;

				&:hover {
					color: $white;
					background-color: $green;
				}
			}
		}
	}

	/* Media queries */
	@include mq('tablet-wide', max) {
		margin-bottom: 20px;

		&__info {
			width: 730px;
		}
	}

	@include mq('tablet-wide', max) {
		&__gallery {
			.item {
				height: 200px;
			}

			.owl-carousel {
				.owl-dots {
					position: initial;
				}
			}
		}

		&__info {
			margin-top: 0;
			background-color: $transparent;
			box-shadow: none;
		}
	}

	@include mq('phablet', max) {
		&__info {
			flex-wrap: wrap;
			padding: 0;

			&-item {
				flex-wrap: wrap;
			}

			&-text {
				padding-right: 0;
			}

			&-action {
				width: 100%;
			}

			&-cta {
				text-align: left;
				padding-top: 15px;
				padding-bottom: 5px;

				a {
					font-size: 14px;
					padding: .35em 1.5em;
				}
			}
		}
	}
}

/* Card person */
.card-person {
	margin-bottom: 15px !important;

	&__wrap {
		padding: 15px;
		position: relative;
		box-shadow: 0 0 10px rgba($black, .2);
	}

	.columinists {
		&__item {
			&-content {
				position: relative;

				.social-simple {
					position: absolute;
					right: 0;
					top: 0;
				}
			}

			&-img {
				margin-top: 0;
			}

			&-text {
				padding-top: 10px;

				span {
					font-family: $font-secondary;
					font-size: 14px;
				}
			}
			
			&-subtitle {
				font-size: 18px;
				font-weight: 600;
				font-style: italic;
				margin-bottom: 0;
			}

			&-info {
				margin-top: 10px;

				h2 {
					font-family: $font-secondary;
					font-size: 30px;
					font-weight: 600;
					margin-bottom: .25em;
				}

				p {
					font-family: $font-secondary;
				}

				a {
					font-family: $font-secondary;
					font-size: 12px;
					font-weight: 600;
					font-style: italic;
					display: inline-block;
					color: $black;
					margin-top: 14px;
				}
			}
		}
	}

	@include mq('tablet', max) {
		.columinists {
			&__item {
				&-content {
					flex-wrap: wrap;

					.social-simple {
						position: initial;
						min-width: 100%;
						flex: 0 0 100%;
						margin: 5px 0 0;
					}
				}
				
				&-info {
					h2 {
						font-size: 20px;
						margin-bottom: 0;
					}

					p {
						display: none;
					}
				}
			}
		}
	}
}