.plans {
	padding-bottom: 40px;
	
	&__content {
		padding-top: 30px;

		&-wrap {
			display: flex;
			flex-wrap: wrap;
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: 25px;

			> div {
				padding-left: 10px;
				padding-right: 10px;
			}
		}

		&-form {
			width: 40%;
			text-align: left;
		}

		&-text {
			width: 60%;
		}
	}

	&__payment-options {
		margin-top: 30px;

		button {
			font-size: 16px;
			color: $black;
			background-color: darken($green-light, 2%);
			width: 285px;
			max-width: 285px;
			height: 40px;
			line-height: 40px;
			padding: 4px 12px;
			border-radius: 6px;
			display: block;
			@extend %center-block;
			@extend %btn;

			+ button {
				margin-top: 20px;
			}
		}
	}

	&__label {
		font-family: $font-secondary;
		font-weight: 200;
		font-size: 24px;
		text-align: center;
		margin-bottom: .75em;
	}

	&__title {
		font-family: $font-secondary;
		font-size: 24px;
		font-weight: 500;
		text-transform: uppercase;
		color: $green;
		margin-bottom: .7em;
		text-align: center;
		letter-spacing: 0.5px;
	}

	&__subtitle {
		font-family: $font-secondary;
		font-size: 20px;
    font-weight: 200;
		color: $black;
		text-align: center;
		margin-bottom: 1.4em;
	}

	&__head {
		font-size: 22px;
		color: $green;
		text-align: center;
		margin-top: 1em;
		margin-bottom: 1.25em;
	}

	&__description {
		font-family: $font-secondary;
		font-size: 16px;
		margin-bottom: 20px;
	}

	&__choose-province {
		margin: 15px 0 42px;

		.select-custom {
			width: 245px;
			max-width: 100%;
			@extend %center-block;
		}
	}

	&__packages {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: stretch;
		width: 845px;
		max-width: 100%;
		@extend %center-block;
	}

	&__row {
		margin-bottom: 40px;
		margin-left: initial;
		margin-right: initial;
		
		&:last-of-type {
			margin-bottom: 0 !important;
		}
	}

	&__package {
		font-family: $font-secondary;
		padding-left: 5px;
		padding-right: 5px;
		width: 33.333%;
		text-align: center;

		&:nth-of-type(1) {
			padding-left: 0;
		}

		&:nth-of-type(3) {
			padding-right: 0;
		}

		&-wrap {
			background-color: lighten($gray, 10%);
			border-radius: 5px;
			height: 100%;
			padding: 0 30px;
			position: relative;
			min-height: 425px;
			overflow: hidden;
		}

		&-header {
			padding: 18px 0 15px;
			position: relative;

			&::after {
				content: '';
				width: 90px;
				height: 1px;
				bottom: 0;
				background-color: $black;
				@include center-elm(true, false);
			}
		}

		&-version {
			&-item {
				font-weight: 500;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 25px;

				> span {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: 8px;


					svg {
						width: 53px;
						height: 35px;
						fill: $red;
					}
				}
			}
		}


		&-title {
			font-size: 20px;
			font-weight: bold;
			color: $green;
		}

		&-text {
			p {
				font-size: 14px;
			}
		}

		&-body {
			position: relative;
			padding: 50px 0 20px;
			min-height: 198px;

			&::after {
				content: '';
				width: 90px;
				height: 1px;
				bottom: 0;
				background-color: $black;
				@include center-elm(true, false);
			}
		}

		&-choose {
			padding: 34px 0 0;
			.select-custom {
				@extend %center-block;
			}
		}

		&-action {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;

			button {
				font-weight: 500;
				color: $white;
				height: 40px;
				line-height: 40px;
				width: 100%;
				background-color: $green;
				transition: all 150ms ease;
				@extend %btn;

				&:hover {
					background-color: darken($green, 5%);
				}
			}
		}
	}

	&__version {
		width: 500px;
		max-width: 100%;
		@extend %center-block;

		.row {
			margin-left: -15px;
			margin-right: -15px;
		}

		[class^="col-"] {
			padding-left: 15px;
			padding-right: 15px;
		}

		@media (max-width: 575px) {
			.row {
				> [class^="col-"] ~ [class^="col-"] {
					margin-top: 20px;
				}
			}
		}
	}

	&__terms {
		text-align: center;
		padding-top: 30px;
		margin-top: -15px;

		p {
			font-family: $font-secondary;

			> a {
				color: $green;
				text-decoration: underline;
			}
		}
	}
	
	&__detail {
		border-radius: 6px;
		border: 1px solid #979797;
		overflow: hidden;

		&-alert {
			font-family: $font-secondary;
			font-size: 18px;
			font-weight: 600;
			color: $red;
			margin-top: -4px;
			margin-bottom: 15px;
		}

		&-block {
			padding: 12px 0;
			border-top: 1px solid #979797;
		}

		&-header {
			font-family: $font-secondary;
			font-weight: normal;
			background-color: $green;
			color: $white;
			padding: 8px 20px;
		}

		&-body {
			background-color: $green-extralight;
			padding: 8px 20px 20px;
		}

		&-legend {
			font-family: $font-secondary;
			font-weight: bold;
			text-transform: uppercase;
			font-size: 16px;
			margin-bottom: 6px;
		}

		&-item {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;

			+ .plans__detail-item {
				margin-top: 22px;
			}

			.text-main {
				font-size: 20px;
			}

			.text-secondary {
				font-family: $font-secondary;
				font-size: 16px;
			}

			.total {
				font-family: $font-secondary;
				font-size: 17px;
			}

			&-text {
				width: calc(100% - 110px);

				.text-title {
					font-family: $font-secondary;
					font-size: 16px;
					font-weight: 500;
					display: block;
					line-height: 1.5;
				}
	
				.text-subtitle {
					font-family: $font-secondary;
					font-size: 14px;
					font-weight: 600;
					display: block;
					line-height: 1.5;
				}

				p {
					font-family: $font-secondary;
					font-size: 16px;
					line-height: 1.5;
					margin-top: 15px;
				}

				ul {
					margin-top: 10px;

					li {
						font-family: $font-secondary;
						font-size: 14px;
						padding-left: 30px;
						position: relative;

						&::before {
							content: '';
							position: absolute;
							left: 4px;
							font-size: 24px;
							background-color: $black;
							width: 5px;
							height: 5px;
							border-radius: 50%;
							top: 5px;
						}

						+ li {
							margin-top: 4px;
						}
					}
				}
			}

			&-price {
				font-family: $font-secondary;
				font-size: 16px;
				font-weight: 600;
				width: 110px;
				min-width: 110px;
				flex-basis: 1;
				flex-grow: 1;
				text-align: right;

				input {
					background-color: #EDF4EE;
					border: 0;
					outline: 0;
				}
			}
		}
	}

	/* Media queries general */
	@include mq('tablet-wide', max) {
		&__package {
			width: 50%;
			margin-bottom: 10px;

			&:nth-of-type(2) {
				padding-right: 0;
			}

			&:nth-of-type(3) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	@include mq('tablet', max) {
		&__title {
			font-family: $font-main;
			font-size: 20px;
			font-weight: bold;
			text-transform: initial;
		}

		&__content {
			&-form,
			&-text {
				width: 100%;
			}

			&-form {
				margin-bottom: 22px;
			}
		}

		&__detail {
			&-header {
				text-align: center;
			}

			&-legend {
				text-align: center;
			}

			&-block {
				+ .plans__detail-block {
					.plans__detail-item-text {
						width: 100%;
					}
				}
			}

			&-item {
				+ .plans__detail-item {
					margin-top: 15px;
				}

				&-text {
					width: calc(100% - 60px);

					.text-main,
					.text-secondary {
						font-size: 15px;
					}

					p,
					ul li {
						font-size: 13px;
					}
				}

				&-price {
					font-size: 15px;
					width: 60px;
  				min-width: 60px;
				}
			}
		}
	}	

	@include mq('phablet', max) {
		&__packages {
			padding-left: 15px;
			padding-right: 15px;
		}

		&__package {
			width: 100%;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		&__detail {
			&-item {
				+.plans__detail-item {
					margin-top: 20px;
				}

				&-text,
				&-price {
					width: 100%;
				}

				&-text {
					margin-bottom: 12px;
				}

				&-price {
					// text-align: left;
				}

				&--total {
					.plans__detail-item-text,
					.plans__detail-item-price {
						width: 50%;
						margin-bottom: 0;
					}

					.plans__detail-item-price {
						text-align: right;
					}
				}
			}
		}
	}
}

