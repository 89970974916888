.staff{
	&__section-principle{
		margin-top: 20px;
	}

	&__items {
		margin-bottom: 25px;
		margin-left: -5px;
		margin-right: -5px;
	}
	&__item {
		margin-bottom: 10px;
		padding-left: 5px;
		padding-right: 5px;

		&-img {
			position: relative;
		}

		&-text {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 10px 20px;
			color: $white;

			&-category {
				font-family: SFProDisplay;
			  font-size: 10px;
			  font-weight: 600;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.4;
			  letter-spacing: 0.4px;

			  &::before {
					content: '';
					width: 5px;
					height: 5px;
					background-color: $white;
					display: inline-block;
					margin-right: 5px;
					position: relative;
					top: -1px;
				}
			}

			&-name{
				font-family: SFProDisplay;
			  font-size: 16px;
			  font-weight: bold;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.2;
			  letter-spacing: 0.4px;
			  padding: 0;
			}
			&-position{
				font-family: SFProDisplay;
			  font-size: 14px;
			  font-weight: normal;
			  font-style: normal;
			  font-stretch: normal;
			  line-height: 1.2;
			  letter-spacing: 0.3px;
			  text-align: left;
			  padding: 0;
			}
		}
	}

	/* Media Queries */
	@include mq('phablet', max) {
		&__item {
			&-text {
				position: initial;
				color: $black;
				padding: 6px 0;

				&-category {
					color: $green;

					&::before {
						background-color: $green;
					}
				}

				&-name {
					font-family: $font-main;
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
	}
}