.audiojs-custom audio {
    position: absolute;
    left: -1px;
}
.audiojs-custom {
    width: 750px;
    max-width: 100%;
    height: 36px;
    background: #F0F0F0;
    overflow: hidden;
    font-family: monospace;
    font-size: 12px;
    @extend %center-block;
}
.audiojs-custom .play-pauseZ {
    width: 32px;
    height: 40px;
    padding: 4px 6px;
    margin: 0px;
    float: left;
    overflow: hidden;
    position: relative;
    // border-right: 1px solid #c00;
}
.audiojs-custom p {
    display: none;
    width: 25px;
    height: 40px;
    margin: 0px;
    cursor: pointer;
}
.audiojs-custom .playZ {
    display: block;
}
.audiojs-custom .scrubberZ {
    position: relative;
    float: left;
    width: calc(100% - 152px);
    background: $light-gray;
    height: 14px;
    margin: 11px 10px;
    border-left: 0px;
    border-bottom: 0px;
    overflow: hidden;
}
.audiojs-custom .progressZ {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 14px;
    width: 0px;
    background: $black;
    z-index: 1;
}
.audiojs-custom .loadedZ {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 14px;
    width: 0px;
    background: lighten($black, 40%);
}
.audiojs-custom .timeZ {
    float: left;
    height: 36px;
    width: 100px;
    line-height: 36px;
    // margin: 0px 0px 0px 6px;
    padding: 0 10px;
    border-left: 1px solid $light-gray;
    color: $black;
    font-size: 11px;
}
.audiojs-custom .timeZ em {
    padding: 0px 2px 0px 0px;
    color: lighten($black, 50%);
    font-style: normal;
}
.audiojs-custom .timeZ strong {
    padding: 0px 0px 0px 2px;
    font-weight: normal;
}
.audiojs-custom .error-messageZ {
    float: left;
    display: none;
    margin: 0px 10px;
    height: 36px;
    width: 400px;
    overflow: hidden;
    line-height: 36px;
    white-space: nowrap;
    color: #fff;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -icab-text-overflow: ellipsis;
    -khtml-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
}
.audiojs-custom .error-messageZ a {
    color: #eee;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid #999;
    white-space: wrap;
}
.audiojs-custom .playZ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background: url('../images/icons/play-audio.png') center/15px 15px no-repeat;
}
.audiojs-custom .loadingZ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background: url('../images/icons/loading-audio.gif') center/18px 18px no-repeat;
}
.audiojs-custom .errorZ {
    background: url('../audiojs/player-graphics.gif') -2px -61px no-repeat;
}
.audiojs-custom .pauseZ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
	height: 36px;
	background: url('../images/icons/pause-audio.png') center/15px 15px no-repeat;
}
.playingZ .playZ, .playingZ .loadingZ, .playingZ .errorZ {
    display: none;
}
.playingZ .pauseZ {
    display: block;
}
.loadingZ .playZ, .loadingZ .pauseZ, .loadingZ .errorZ {
    display: none;
}
.loadingZ .loadingZ {
    display: block;
}
.errorZ .timeZ, .errorZ .playZ, .errorZ .pauseZ, .errorZ .scrubberZ, .errorZ .loadingZ {
    display: none;
}
.errorZ .errorZ {
    display: block;
}
.errorZ .play-pauseZ p {
    cursor: auto;
}
.errorZ .error-messageZ {
    display: block;
}