.queen {
	.title {
		margin-top: 0;
	}

	&__list {
		align-items: flex-start;
	}

	&__item {
		&-content {
			position: relative;		
		}

		&-img {
			display: block;
			overflow: hidden;
		}

		&-text {
			padding: 10px 10px 0;
		}

		&-name {
			font-family: RobotoSlab;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: 0.4px;
			text-align: left;
			color: $black;
			margin-top: 7px;

			a {
				color: $black;
			}
		}

		&-category {
			font-family: SFProDisplay;
			font-size: 10px;
			font-weight: 600;
			font-style: normal;
			font-stretch: normal;
			line-height: 1;
			letter-spacing: 0.4px;
			text-align: left;
			color: $green;

			&::before {
				content: '';
				width: 5px;
				height: 5px;
				background-color: $green;
				display: inline-block;
				margin-right: 5px;
				position: relative;
				top: -1px;
			}
		}

		&-date {
			font-family: RobotoSlab;
			font-size: 10px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.4;
			letter-spacing: 0.2px;
			text-align: left;
			color: $black;
			margin-top: 7px;
			margin-bottom: 25px;
		}
	}

	&__action {
		margin-bottom: 0;
	}

	@include mq('tablet', max) {
		&__item {
			&--featured {
				.queen__item-content {
					max-width: 320px;
			    margin-left: auto;
			    margin-right: auto;
				}

		    .queen__item-img {
					display: block;
			    max-height: 350px;
			    overflow: hidden;
		    }
			}
		}
	}
}

.publi-queen {
	width: 265px;
  	height: 350px;
  	margin-top: 10px;
  	margin-bottom:10px;
    background-image: linear-gradient(to bottom, #a6f3ab, #75eb7d);

	h2 {
		font-size: 25px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.4px;
		text-align: center;
		color: $black;
		margin-top: 100px;
		margin-bottom: 50px;
	}
	span{
		color: red;
	}

	h3{
		font-family: SFProDisplay;
		font-size: 15px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.9px;
		text-align: center;
		color: $black;
	}
}
.boton {
	text-align: center;

	button {
		width: 250px;
    height: 35px;
    background-color: #ffffff;
    border: solid 1px #16a730;
		font-family: SFProDisplay;
		font-size: 16px;
		text-transform: uppercase;
		color: #00b23f;
		font-weight: bold;
	}
}

