/* Placeholder with styles of general title */
%title {
	margin: 0 0 20px;
	border-style: solid;
	border-width: 1px;
	border-color: #ccc;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 0 0 auto;
	width: 100%;
	
	h3 {
		background-color: #ccc;
		color: $white;
		font-size: 16px;
		padding: .4em .5em;
		display: inline-block;
		position: relative;
		min-width: 200px;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			border-width: 1.0142em .5em;
			border-style: solid;
			border-color: transparent;
			border-left-color: #ccc;
		}
	}

	@include mq('tablet', min) {
		h3 {
			font-size: 22px;
		}
	}
}

/* Mixin custom color title */
@mixin title-custom($color) {
	border-color: $color;

	h3 {
		background-color: $color;

		&::after {
			border-left-color: $color;
		}

	}
}


/* ===========================
	 TITLE
	 =========================== */
.title {
	@extend %title;

	&--green {
		@include title-custom($green);

		svg {
			fill: $green;
		}

		.title__link {
			@include link-arrow($green);
		}
	}

	&--red-dark {
		@include title-custom($red-dark);

		svg {
			fill: $red-dark;
		}

		.title__link {
			@include link-arrow($red-dark);
		}
	}

	&--purple {
		@include title-custom($purple);

		svg {
			fill: $purple;
		}

		.title__link {
			@include link-arrow($purple);
		}
	}

	&--gray-dark {
		@include title-custom($gray-dark);

		svg {
			fill: $gray-dark;
		}

		.title__link {
			@include link-arrow($gray-dark);
		}
	}

	&--blue-dark {
		@include title-custom($blue-dark);

		svg {
			fill: $blue-dark;
		}

		.title__link {
			@include link-arrow($blue-dark);
		}
	}

	&--blue-light2 {
		@include title-custom($blue-light2);

		svg {
			fill: $blue-light2;
		}

		.title__link {
			@include link-arrow($blue-light2);
		}
	}

	&--lightblue {
		@include title-custom(#0099CC);

		svg {
			fill: #0099CC;
		}

		.title__link {
			@include link-arrow(#0099CC);
		}
	}

	&--turquoise {
		@include title-custom(#3F91AC);

		svg {
			fill: #3F91AC;
		}

		.title__link {
			@include link-arrow(#3F91AC);
		}
	}

	&--violet {
		@include title-custom($violet);

		svg {
			fill: $violet;
		}

		.title__link {
			@include link-arrow($violet);
		}
	}

	&--orange {
		@include title-custom($orange);

		svg {
			fill: $orange;
		}

		.title__link {
			@include link-arrow($orange);
		}
	}

	&__link {
		margin-right: 12px;

		a {
			font-size: 16px !important;
		}
	}

	@include mq('phone-wide', max) {
		.title__link {
			display: none;
		}
	}
}

.title-search {
	h3 {
		color: #7d7d7d;
	  font-size: 22px;
	  margin: -6px 0 18px;
	  letter-spacing: 0.5px;
	}

	/* Media queries */
	@include mq('tablet', max) {
		h3 {
			font-size: 18px;
		}
	}
}
